<template>
    <div>
        <div :id="questionData.question_id" class="sqpie-chart"></div>
    </div>
</template>
<script>
    import API from '@/api'
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";
    export default {
        props: ['questionData', 'chartType', 'date_from', 'date_to'],
        data() {
            return {
                chartType2: this.chartType, 
                questionsData: [],
                form: {
                    date_from: '',
                    date_to: '',
                },
                datacollection: {
                    labels: [],
                    datasets: [{
                        data: [],
                    }]
                },
                options: {
                    responsive: true,
                    animation: {
                        animateScale: true,
                        animateRotate: true
                    }
                }
            }
        },
        beforeMount() {
            this.form.date_from = this.date_from;
            this.form.date_to = this.date_to;
            // this.selected_client_id = JSON.parse(localStorage.getItem('client_id'));
            // this.form.client_id = this.selected_client_id;
            // this.getChartData();
        },
        // watch: {
        //     questionData: function(newVal) {
        //        this.questionData =  newVal;
        //        // this.getChartData();
        //     },
        //     'datacollection'(to, from) {
        //         this.renderChart(this.datacollection, this.options)
        //         // this.$data._chart
        //     }
        // },
        mounted() {
            if(this.chartType2 === 'true') {
                this.getChartData();
            } else {
                this.getBarChartData();
            }
        },
        created() {
            // this.$EventBus.$on("filterByDate", (data) => {
            //     console.log('filterByDate cellellled:');
            //     this.getFormData(data);
            // });
            // this.$EventBus.$on("resetForm", (data) => {
            //     console.log('reset cellellled:');
            //     this.getFormData(data);
            // });
        },
        methods: {
            getFormData(data) {
                this.form.date_from = data.date_from;
                this.form.date_to = data.date_to;
                this.updateChartData()
            },
            getChartData() {
                        // am4core.options.autoDispose = true;
                        var sportArray = JSON.parse(JSON.stringify(this.questionData.answers));
                        var data = [];
                        console.log('sportArray', this.questionData);
                        // data.push(JSON.parse(JSON.stringify(sportArray.answers)));
                        var chart = am4core.create(`${this.questionData.question_id}` , am4charts.PieChart);  
                        if(chart.logo){
                            chart.logo.disabled = true;
                        }
                        chart.data = sportArray;
                        var pieSeries = chart.series.push(new am4charts.PieSeries());
                        pieSeries.dataFields.value = "answer_count";
                        pieSeries.dataFields.category = "answer";
                        // chart.events.on("ready", function(ev){
                        //     hideIndicator();
                        // });

                        // let indicator;
                        // let indicatorInterval;

                        // function showIndicator() {
                        // if (!indicator) {
                        //     indicator = chart.tooltipContainer.createChild(am4core.Container);
                        //     indicator.background.fill = am4core.color("#fff");
                        //     indicator.width = am4core.percent(100);
                        //     indicator.height = am4core.percent(100);

                        //     var indicatorLabel = indicator.createChild(am4core.Label);
                        //     indicatorLabel.text = "Loading ...";
                        //     indicatorLabel.align = "center";
                        //     indicatorLabel.valign = "middle";
                        //     indicatorLabel.dy = 50;
                            
                        //     var hourglass = indicator.createChild(am4core.Image);
                        //     hourglass.href = "https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-160/hourglass.svg";
                        //     hourglass.align = "center";
                        //     hourglass.valign = "middle";
                        //     hourglass.horizontalCenter = "middle";
                        //     hourglass.verticalCenter = "middle";
                        //     hourglass.scale = 0.4;
                        // }

                        // indicator.hide(0);
                        // indicator.show();

                        // clearInterval(indicatorInterval);
                        // indicatorInterval = setInterval(function() {
                        //     hourglass.animate([{
                        //     from: 0,
                        //     to: 360,
                        //     property: "rotation"
                        //     }], 2000);
                        // }, 3000);
                        // }

                        // function hideIndicator() {
                        // indicator.hide();
                        // clearInterval(indicatorInterval);
                        // }

                        // showIndicator();
                        pieSeries.ticks.template.disabled = true;
                        pieSeries.alignLabels = false;
                        pieSeries.labels.template.text = "{value}";
                        pieSeries.labels.template.radius = am4core.percent(-40);
                        pieSeries.labels.template.fill = am4core.color("white");
                        chart.legend = new am4charts.Legend();
                        chart.legend.maxWidth = 150;
                        chart.legend.position = "right";
                        chart.legend.scrollable = true;
                        chart.responsive.enabled = true;

                        window.addEventListener('resize', () => {
                            if (window.innerWidth <= 1199) {
                                chart.legend.position = "bottom";
                            } else {
                                chart.legend.position = "right";
                            }
                        }, true);
            },
            updateChartData() {
                var sportArray = JSON.parse(JSON.stringify(this.questionData.answers));
                        var data = [];
                        console.log('sportArray', this.questionData);
                        var chart = am4core.create(`${this.questionData.question_id}` , am4charts.PieChart);  
                        if(chart.logo){
                            chart.logo.disabled = true;
                        }
                        chart.data = sportArray;
                        var pieSeries = chart.series.push(new am4charts.PieSeries());
                        pieSeries.dataFields.value = "answer_count";
                        pieSeries.dataFields.category = "answer";
                        // chart.events.on("ready", function(ev){
                        //     hideIndicator();
                        // });

                        // let indicator;
                        // let indicatorInterval;

                        // function showIndicator() {
                        // if (!indicator) {
                        //     indicator = chart.tooltipContainer.createChild(am4core.Container);
                        //     indicator.background.fill = am4core.color("#fff");
                        //     indicator.width = am4core.percent(100);
                        //     indicator.height = am4core.percent(100);

                        //     var indicatorLabel = indicator.createChild(am4core.Label);
                        //     indicatorLabel.text = "Loading ...";
                        //     indicatorLabel.align = "center";
                        //     indicatorLabel.valign = "middle";
                        //     indicatorLabel.dy = 50;
                            
                        //     var hourglass = indicator.createChild(am4core.Image);
                        //     hourglass.href = "https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-160/hourglass.svg";
                        //     hourglass.align = "center";
                        //     hourglass.valign = "middle";
                        //     hourglass.horizontalCenter = "middle";
                        //     hourglass.verticalCenter = "middle";
                        //     hourglass.scale = 0.4;
                        // }

                        // indicator.hide(0);
                        // indicator.show();

                        // clearInterval(indicatorInterval);
                        // indicatorInterval = setInterval(function() {
                        //     hourglass.animate([{
                        //     from: 0,
                        //     to: 360,
                        //     property: "rotation"
                        //     }], 2000);
                        // }, 3000);
                        // }

                        // function hideIndicator() {
                        // indicator.hide();
                        // clearInterval(indicatorInterval);
                        // }

                        // showIndicator();
                        pieSeries.ticks.template.disabled = true;
                        pieSeries.alignLabels = false;
                        pieSeries.labels.template.text = "{value}";
                        pieSeries.labels.template.radius = am4core.percent(-40);
                        pieSeries.labels.template.fill = am4core.color("white");
                        chart.legend = new am4charts.Legend();
                        chart.legend.maxWidth = 150;
                        chart.legend.position = "right";
                        chart.legend.scrollable = true;
                        chart.responsive.enabled = true;

                        window.addEventListener('resize', () => {
                            if (window.innerWidth <= 1199) {
                                chart.legend.position = "bottom";
                            } else {
                                chart.legend.position = "right";
                            }
                        }, true);
            },
            getBarChartData() {
                // am4core.options.autoDispose = true;
                var sportArray = JSON.parse(JSON.stringify(this.questionData.answers));
                var data = [];
                console.log('sportArray', this.questionData);
                // data.push(JSON.parse(JSON.stringify(sportArray.answers)));
                am4core.useTheme(am4themes_animated);
                var chart = am4core.create(`${this.questionData.question_id}` , am4charts.XYChart);  
                if(chart.logo){
                    chart.logo.disabled = true;
                }
                chart.data = sportArray;

                let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "answer";
                categoryAxis.renderer.grid.template.location = -12;
                categoryAxis.renderer.minGridDistance = 30;
                let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

                // Create series
                let series = chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.valueY = "answer_count";
                series.dataFields.categoryX = "answer";
                series.name = "answer_count";
                series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
                series.columns.template.fillOpacity = .8;

                let columnTemplate = series.columns.template;
                columnTemplate.strokeWidth = 2;
                columnTemplate.strokeOpacity = 1;
                chart.responsive.enabled = true;
            },
            updateBarChartData() {
                var sportArray = JSON.parse(JSON.stringify(this.questionData.answers));
                var data = [];
                console.log('sportArray', this.questionData);
                // data.push(JSON.parse(JSON.stringify(sportArray.answers)));
                var chart = am4core.create(`${this.questionData.question_id}` , am4charts.XYChart);  
                if(chart.logo){
                    chart.logo.disabled = true;
                }
                chart.data = sportArray;

                let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "answer";
                categoryAxis.renderer.grid.template.location = -12;
                categoryAxis.renderer.minGridDistance = 30;
                let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

                // Create series
                let series = chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.valueY = "answer_count";
                series.dataFields.categoryX = "answer";
                series.name = "answer_count";
                series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
                series.columns.template.fillOpacity = .8;

                let columnTemplate = series.columns.template;
                columnTemplate.strokeWidth = 2;
                columnTemplate.strokeOpacity = 1;
                chart.responsive.enabled = true;
            },
        }
    }
</script>
<style >
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.sqpie-chart{
  width: 100%;
  height: 330px;
}

@media (max-width: 480px) {
    .sqpie-chart {
        height: 350px;
    }
}

</style>