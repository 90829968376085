<template>
	<div class="language-dropdown">
		<img v-if="$i18n.locale === 'en'" src="../../assets/images/flags/en.png" alt="icon">
		<img v-if="$i18n.locale === 'es'" src="../../assets/images/flags/es.png" alt="icon">
		<img v-if="$i18n.locale === 'fr'" src="../../assets/images/flags/fr.png" alt="icon">
		<img v-if="$i18n.locale === 'de'" src="../../assets/images/flags/de.png" alt="icon">
		<img v-if="$i18n.locale === 'no'" src="../../assets/images/flags/no.png" alt="icon">
		<img v-if="$i18n.locale === 'sw'" src="../../assets/images/flags/sw.png" alt="icon">
		<b-dropdown id="dropdown-1" :text="getText()" class="m-md-2 drop-menu" @change="switchlanguage()">
			<b-dropdown-item @click="switchlanguage('en')"><img src="../../assets/images/en-language.png" alt="icon"> <span>English</span></b-dropdown-item>
			<b-dropdown-item class="es-drop-item" @click="switchlanguage('es')"><img src="../../assets/images/flags/es.png" alt="icon"> <span>Spanish</span></b-dropdown-item>
			<b-dropdown-item class="es-drop-item" @click="switchlanguage('fr')"><img src="../../assets/images/flags/fr.png" alt="icon"> <span>French</span></b-dropdown-item>
			<b-dropdown-item class="es-drop-item" @click="switchlanguage('de')"><img src="../../assets/images/flags/de.png" alt="icon"> <span>German</span></b-dropdown-item>
			<b-dropdown-item class="es-drop-item" @click="switchlanguage('no')"><img src="../../assets/images/flags/no.png" alt="icon"> <span>Norwegian</span></b-dropdown-item>
			<b-dropdown-item class="es-drop-item" @click="switchlanguage('sw')"><img src="../../assets/images/flags/sw.png" alt="icon"> <span>Swahili</span></b-dropdown-item>
		</b-dropdown>
	</div>
	<!-- <div class="language-dropdown">
          <div class="gtranslate_wrapper bothcss"></div>
	</div> -->
</template>

<script>
    import i18n from '@/i18n';
	import Vue from 'vue';

	export default {
		name: "SwitchLocale",
		props: ['user'],
		data() {
			return { }
		},
		methods: {
			getText() {
				const value = i18n.locale;
				if (value === 'en') {
					return 'English';
				} else if (value === 'es') {
					return 'Spanish';
				} else if (value === 'fr') {
					return 'French';
				} else if (value === 'de') {
					return 'German';
				} else if (value === 'no') {
					return 'Norwegian';
				} else if (value === 'sw') {
					return 'Swahili';
				}
			},
			switchlanguage(locale) {
				i18n.locale = locale;
				if(this.user == 'admin') {
					this.$store.commit('setAdminLanguage')
				} else if(this.user == 'spotter') {
					this.$store.commit('setSpotterLanguage')
				}
				this.$router.go();
			}
		},
		mounted() {
			var previsousState;
			console.log('this.user:', this.user);
			if(this.user == 'admin') {
				previsousState = this.$store.state.adminLanguage;
				console.log('previous state admin: ', this.$store.state.adminLanguage);
				if(previsousState) {
					i18n.locale = this.$store.state.adminLanguage;
				}
			} else if(this.user == 'spotter') {
				previsousState = this.$store.state.spotterLanguage;
				console.log('previous state admin: ', this.$store.state.spotterLanguage);
				if(previsousState) {
					i18n.locale = this.$store.state.spotterLanguage;
				}
			}
			// let Script = document.createElement("script");
			// Script.setAttribute("src", "https://cdn.gtranslate.net/widgets/latest/dropdown.js");
			// document.head.appendChild(Script);
		}
	};
</script>

<style>
	.bothcss {
	display: inline-flex;
	padding-right: 5px;
	}
	.dropdown .dropdown-toggle:after {
		display: none;
	}
	
	.drop-menu {
		margin-left: 0 !important;
	}
	
	.es-drop-item .dropdown-item img {
		height: 25px;
	}
	
	.language-dropdown img {
		width: 25px;
		/* height: 14px; */
	}
	
	.language-dropdown .dropdown .dropdown-menu {
		position: absolute;
		top: 55px;
		border-radius: 0.358rem;
		box-shadow: 0 5px 25px rgb(34 41 47 / 10%);
		left: auto;
		padding: 0.5rem 0;
		margin: 0 0 0;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid rgba(34, 41, 47, .05);
		z-index: 10;
		min-width: 10rem;
		font-size: 1rem;
		color: #6e6b7b;
		list-style: none;
	}

	.lang-e3 .language-dropdown .dropdown .dropdown-menu {
		top: 55px !important;
	}
	.lang-e4 .language-dropdown .dropdown .dropdown-menu {
		top: 15px !important;
	}
	
	.language-dropdown .dropdown .dropdown-menu li .dropdown-item {
		padding: 0.65rem 1.28rem;
	}
	@media (max-width: 550px) {
		.language-dropdown .dropdown .dropdown-menu {
			left: -55px !important;
		}
	}
	@media (max-width: 375px) {
		.language-dropdown .dropdown button {
			padding: 4px !important;
		}
	}
</style>