<template>
    <div>
        <b-modal id="modal-analytics" :title="$t('analytics')" size="lg" :hide-footer="true"
                 modal-class="custom-lg-modal">
            <div class="row">
                <div class="col-md-3 grid-margin stretch-card dashboard-box box_01">
                    <div class="card">
                        <div class="card-body">
                            <p class="card-title mb-0">{{ $t('total-spots') }}</p>
                            <div
                                    class="dashboard-box-counter"
                            >
                                <h3 class="">{{total_spots}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 grid-margin stretch-card dashboard-box box_02">
                    <div class="card">
                        <div class="card-body">
                            <p class="card-title mb-0">{{ $t('this-year') }}</p>
                            <div
                                    class="dashboard-box-counter"
                            >
                                <h3 class="">{{this_year}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 grid-margin stretch-card dashboard-box box_03">
                    <div class="card">
                        <div class="card-body">
                            <p class="card-title mb-0">{{quarter_name}}</p>
                            <div
                                    class="dashboard-box-counter"
                            >
                                <h3>{{quarter_count}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 grid-margin stretch-card dashboard-box box_04">
                    <div class="card">
                        <div class="card-body">
                            <p class="card-title mb-0">{{month_name}}</p>
                            <div
                                    class="dashboard-box-counter"
                            >
                                <h3>{{month_count}}</h3>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <!-- <div class="col-lg-6 col-md-12 grid-margin stretch-card line-bar-chart">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">{{ $t('project-validity') }}</h4>
                        </div>
                        <div class="card-body">
                            <div id="spot_chart">
                                <totalSpotValidity></totalSpotValidity>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-lg-6 col-md-12 grid-margin stretch-card line-bar-chart">
                    <div class="card">
                        <div class="card-header drop-down-header">
                            <h4 class="card-title">{{ $t('spots-per-month') }}</h4>
                            <div>
                                <b-form-select v-model="dropDownComponentForSpotsPerMonth">
                                    <b-form-select-option value="0">{{ $t('pie-chart') }}</b-form-select-option>
                                    <b-form-select-option value="1">{{ $t('bar-chart') }}</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="card-body">
                            <div id="spot_chart">
                                <spotChartPieCountPerMonth v-if="dropDownComponentForSpotsPerMonth == 0" :date_from="form.date_from" :date_to="form.date_to"></spotChartPieCountPerMonth>
                                <spotChartCanvasCountPerMonth v-if="dropDownComponentForSpotsPerMonth == 1" :date_from="form.date_from" :date_to="form.date_to"></spotChartCanvasCountPerMonth>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 grid-margin">
                    <div class="card">
                        <div class="card-header">
                              <h4 class="card-title">{{ $t('spotters') }}</h4>
                        </div>
                        <div class="card-body">
                            <vue-good-table
                                    mode="remote"
                                    @on-page-change="onPageChange"
                                    @on-sort-change="onSortChange"
                                    @on-column-filter="onColumnFilter"
                                    @on-per-page-change="onPerPageChange"
                                    :totalRows="totalRecords"
                                    :isLoading.sync="isLoading"
                                    :pagination-options="{enabled: true,dropdownAllowAll: false,perPageDropdown: [10, 20, 50, 100, 200],}"
                                    :rows="rows"
                                    :columns="columns"
                                    @on-search="searchFn"
                                    :search-options="{enabled: true,searchFn: searchFn}">
                            </vue-good-table>
                        </div>
                    </div>
                </div>
                <div class="col-md-6  grid-margin stretch-card chat-box">
                    <div class="card">
                        <div class="card-header drop-down-header">
                            <h4 class="card-title">{{ $t('spots-per-location') }}</h4>
                            <div>
                                <b-form-select v-model="dropDownComponentForLocation">
                                    <b-form-select-option value="0">{{ $t('pie-chart') }}</b-form-select-option>
                                    <b-form-select-option value="1">{{ $t('bar-chart') }}</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="card-body" >
                            <spotLocationCountPie v-if="dropDownComponentForLocation == 0" :date_from="form.date_from" :date_to="form.date_to"></spotLocationCountPie>
                            <spotLocationCountBar v-if="dropDownComponentForLocation == 1" :date_from="form.date_from" :date_to="form.date_to"></spotLocationCountBar>
                            
                        </div>
                    </div>
                </div>
                <div class="col-md-6  grid-margin stretch-card chat-box">
                    <div class="card">
                        <div class="card-header drop-down-header">
                            <h4 class="card-title">{{ $t('spots-per-type') }}</h4>
                            <div>
                                <b-form-select v-model="dropDownComponentForSpotType">
                                    <b-form-select-option value="0">{{ $t('pie-chart') }}</b-form-select-option>
                                    <b-form-select-option value="1">{{ $t('bar-chart') }}</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="card-body">
                            <spotTypeCountPie v-if="dropDownComponentForSpotType == 0" :date_from="form.date_from" :date_to="form.date_to"></spotTypeCountPie>
                            <spotTypeCountBar v-if="dropDownComponentForSpotType == 1" :date_from="form.date_from" :date_to="form.date_to"></spotTypeCountBar>
                        </div>
                    </div>
                </div>
                <div class="col-md-6  grid-margin stretch-card chat-box">
                    <div class="card">
                        <div class="card-header drop-down-header">
                            <h4 class="card-title">{{ $t('spots-per-category') }}</h4>
                            <div>
                                <b-form-select v-model="dropDownComponentForCategory">
                                    <b-form-select-option value="0">{{ $t('pie-chart') }}</b-form-select-option>
                                    <b-form-select-option value="1">{{ $t('bar-chart') }}</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="card-body">
                            <categoryCountPie v-if="dropDownComponentForCategory == 0" :date_from="form.date_from" :date_to="form.date_to"></categoryCountPie>
                            <categoryCountBar v-if="dropDownComponentForCategory == 1" :date_from="form.date_from" :date_to="form.date_to"></categoryCountBar>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-3 col-md-6  grid-margin stretch-card chat-box" v-for="(question, index) in questions"
                     :key="index">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">{{question.question}}</h4>
                        </div>
                        <div class="card-body">
                            <pieChartPerQuestion :questionData="question.answers"></pieChartPerQuestion>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-6  grid-margin stretch-card chat-box" v-for="(question, index) in questions"
                     :key="index">
                     <div class="card">
                        <div class="card-header drop-down-header">
                            <h4 class="card-title">{{question.question}}</h4>
                            <div>
                                <!-- <b-form-select v-model="dropDownComponentForQuestion">
                                    <b-form-select-option value="0">{{ $t('pie-chart') }}</b-form-select-option>
                                    <b-form-select-option value="1">{{ $t('bar-chart') }}</b-form-select-option>
                                </b-form-select> -->
                                <select name="LeaveType" class="custom-select" v-model="question.chartType">
                                    <option value="true">Pie Chart</option>
                                    <option value="false">Bar Chart</option>
                                </select>
                            </div>
                        </div>
                        <div class="card-body">
                             <spotQuestionCountPie :chartType="question.chartType" :questionData="question" :key="question.chartType" :date_from="form.date_from" :date_to="form.date_to"></spotQuestionCountPie>
                            <!-- <spotQuestionCountPie v-if="dropDownComponentForQuestion == 0" :questionData="question"></spotQuestionCountPie>
                            <spotQuestionCountBar v-if="dropDownComponentForQuestion == 1" :questionData="question"></spotQuestionCountBar> -->
                        </div>
                    </div>
                </div>
            </div>

        </b-modal>
    </div>
</template>
<script>
    import API from '@/citizen_api'
    // import spotterSpotChartCanvas from "../citizen/spotChartCanvas";
    // import totalSpotValidity from  "../components/charts/am-charts/totalSpotValidity";
    import spotChartCanvasCountPerMonth from "../components/charts/am-charts/spotChartCanvasCountPerMonth";
    import spotLocationCountPie from "../components/charts/am-charts/spotLocationCountPie";
    import spotTypeCountPie from "../components/charts/am-charts/spotTypeCountPie";
    import categoryCountPie from "../components/charts/am-charts/categoryCountPie";
    // import pieChartPerQuestion from "../citizen/pieChartPerQuestion";
    import spotQuestionCountPie from "../components/charts/am-charts/spotQuestionCountPie";
    import spotChartPieCountPerMonth from "../components/charts/am-charts/spotChartPieCountPerMonth";
    // import spotQuestionCountBar from "../components/charts/am-charts/spotQuestionCountBar";
    import spotLocationCountBar from "../components/charts/am-charts/spotLocationCountBar";
    import spotTypeCountBar from "../components/charts/am-charts/spotTypeCountBar";
    import categoryCountBar from "../components/charts/am-charts/categoryCountBar";

    export default {
        name: 'Analytics-Dashboard',
        components: {
            // totalSpotValidity,
            spotChartCanvasCountPerMonth,
            // spotterSpotChartCanvas,
            spotLocationCountPie,
            spotTypeCountPie,
            categoryCountPie,
            // pieChartPerQuestion
            spotQuestionCountPie,
            spotChartPieCountPerMonth,
            spotLocationCountBar,
            spotTypeCountBar,
            categoryCountBar,
            // spotQuestionCountBar,
        },
        data() {
            return {
                dropDownComponentForLocation: 0,
                dropDownComponentForCategory:0,
                dropDownComponentForSpotType:0,
                dropDownComponentForQuestion:0,
                dropDownComponentForSpotsPerMonth:1,
                latitude: '',
                form: {
                    date_from: '',
                    date_to: '',
                    client_url: window.location.href.split('#')[0]
                },
                total_spots: '',
                this_year: '',
                quarter_name: '',
                quarter_count: '',
                month_name: '',
                month_count: '',
                questions: '',
                serverParams: {
                    client_url: window.location.href.split('#')[0],
                    // a map of column filters example: {name: 'john', age: '20'}
                    columnFilters: {},
                    sort: [
                        {
                            field: 'spots_count', // example: 'name'
                            type: 'desc' // 'asc' or 'desc'
                        }
                    ],

                    page: 1, // what page I want to show
                    perPage: 5 // how many items I'm showing per page
                },
                columns: [
                    {
                        label: 'Username',
                        field: 'user_name',
                        filterable: true
                    },
                    // {
                    //     label: 'Name',
                    //     field: 'name',
                    //     filterable: true
                    // },
                    // {
                    //     label: 'Phone',
                    //     field: 'mobile_phone',
                    //     sortable: true,
                    // },
                    {
                        label: 'Total Spots',
                        field: 'spots_count',
                        sortable: true,
                    },
                ],
                rows: [],
                totalRecords: 0,
                isLoading: false,
            };
        },
        computed: {},
        beforeMount() {
            // this.getAppSetting();
        },
        mounted() {
        },
        created() {
            this.$EventBus.$on("showAnalytics", (data) => {
                this.openAnalytics();
            });
        },
        methods: {
            onChange(question,index, event) {
                if(event.target.value === 'pie') {
                    this.questions[index].pieChart = true;
                    this.questions[index].barChart = false;
                }
                if(event.target.value === 'bar') {
                    this.questions[index].pieChart = false;
                    this.questions[index].barChart = true;
                }
            },
            openAnalytics() {
                this.$root.$emit("bv::show::modal", "modal-analytics");
                this.serverParams.columnFilters = {};
                this.searchTerm = {};
                this.getSpotterDashboardData();
                this.loadItems()
                this.getSpotterQuestionsData();

            },
            getSpotterDashboardData() {
                API.getSpotterDashboardData(this.form,
                    data => {
                        this.total_spots = data.data.total_spots;
                        this.this_year = data.data.yearly_spots;
                        this.quarter_name = 'Q' + data.data.current_quarter;
                        this.quarter_count = data.data.quarterly_spots;
                        this.month_name = data.data.current_month_name;
                        this.month_count = data.data.monthly_spots;
                    },
                    err => {
                    }
                )
            },
            getSpotterQuestionsData() {
                API.getSpotterQuestionsData(this.form,
                    data => {
                        this.questions = data.data;
                    },
                    err => {
                    }
                )
            },
            loadItems() {
                API.getSpotterSpotCount(this.serverParams,
                    data => {
                        this.totalRecords = data.data.total;
                        this.rows = data.data.data;
                    },
                    err => {
                    }
                )
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage});
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            searchFn(params) {
                this.updateParams({searchTerm: params});
                this.loadItems();
            },
        }
    };
</script>
<style>
.drop-down-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (max-width: 375px) {
        .card .card-body {
            padding: 0 !important;
        }
        .new-updated-design h4.card-title {
            font-size: 15px;
        }
        .custom-select {
            font-size: 14px;
        }
    }
</style>

