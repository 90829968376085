<template>
    <div>
        <b-modal id="modal-forgetPassword" :title="title" :hide-footer="true">
            <b-alert v-model="showDismissibleAlert" :variant="variant" dismissible>
                {{alertMessage}}
            </b-alert>
            <div v-if="step === 1">
                <form>
                    <b-form-group
                            id="detail"
                            label="Enter Mobile Phone Number"
                            label-for="detail"
                    >
                        <b-form-input
                                type="text"
                                id="mobile_phone"
                                placeholder="Enter Mobile Phone Number"
                                v-model="$v.form.email.$model"
                                :state="$v.form.email.$dirty ? !$v.form.email.$error : null"></b-form-input>
                        <b-form-invalid-feedback v-if="!$v.form.email.required"
                                                 id="input-2-live-feedback">
                                                 {{ $t("field-required") }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <div class="mt-3">
                        <button type="button" class="btn btn-dark btn-lg btn-block"
                                :disabled="this.$v.form.$anyError || isDisabled" @click="forgetPassword()">Forget
                            Password
                        </button>
                    </div>
                </form>
            </div>
            <div v-if="step === 2">
                <b-form-group
                        id="input-group-1"
                        label="Email Address or Mobile Phone Number*"
                        label-for="input-1"
                >
                    <b-form-input
                            id="input-1"
                            type="email"
                            autocomplete="new-password"
                            placeholder="Email Address or Mobile Phone Number"
                            v-model="$v.update_profile.email.$model"
                            :state="$v.update_profile.email.$dirty ? !$v.update_profile.email.$error : null"
                            aria-describedby="input-2-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.update_profile.email.required" id="input-2-live-feedback">
                        {{ $t("field-required") }}
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                        id="input-group-1"
                        label="OTP*"
                        label-for="input-1"
                >
                    <b-form-input
                            id="input-1"
                            type="text"
                            autocomplete="new-password"
                            placeholder="OTP"
                            v-model="$v.update_profile.otp.$model"
                            :state="$v.update_profile.otp.$dirty ? !$v.update_profile.otp.$error : null"
                            aria-describedby="input-2-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.update_profile.otp.required" id="input-2-live-feedback">
                        {{ $t("field-required") }}
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                        id="input-group-2"
                        label="Password*"
                        label-for="input-2"
                >
                                          <span class="icon is-small is-right"
                                                style="">
                                        <b-form-input
                                                id="input-2"
                                                :type="inputType"
                                                placeholder="Password"
                                                autocomplete="new-password"
                                                v-model="$v.update_profile.password.$model"
                                                :state="$v.update_profile.password.$dirty ? !$v.update_profile.password.$error : null"
                                                aria-describedby="input-3-live-feedback"
                                        ></b-form-input>
                                         <b-form-invalid-feedback v-if="!$v.update_profile.password.required"
                                                                  id="input-3-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
                                         <b-form-invalid-feedback v-if="!$v.update_profile.password.minLength"
                                                                  id="input-3-live-feedback">Password must have at least {{ $v.update_profile.password.$params.minLength.min }} letters.</b-form-invalid-feedback>
<!--                                                                                       <b-form-invalid-feedback-->
<!--                                                                                               v-if="update_profile.password && !$v.update_profile.password.valid"-->
<!--                                                                                               id="input-3-live-feedback">Password contains atleast One Uppercase, One Lowercase, One Number and One Special Character.</b-form-invalid-feedback>-->
                                      <i @click="toggleShow" class="fas password-icon"
                                         :class="{'fa fa-eye': showPassword, 'fa fa-eye-slash': !showPassword, }"></i></span>

                </b-form-group>
                <b-form-group
                        id="input-group-4"
                        label="Confirm Password*"
                        label-for="input-4"
                >
                                          <span class="icon is-small is-right"
                                                style="">
                                        <b-form-input
                                                id="input-4"
                                                autocomplete="new-password"
                                                :type="inputType1"
                                                placeholder="Confirm Password"
                                                v-model="$v.update_profile.password_confirmation.$model"
                                                :state="$v.update_profile.password_confirmation.$dirty ? !$v.update_profile.password_confirmation.$error : null"
                                                aria-describedby="input-4-live-feedback"
                                        ></b-form-input>
                                              <b-form-invalid-feedback
                                                      v-if="!$v.update_profile.password_confirmation.required"
                                                      id="input-3-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
                                         <b-form-invalid-feedback
                                                 v-if="!$v.update_profile.password_confirmation.sameAsPassword"
                                                 id="input-4-live-feedback">Passwords must be identical.</b-form-invalid-feedback>
                                      <i @click="toggleConfirmShow" class="fas password-icon"
                                         :class="{'fa fa-eye': showPassword1, 'fa fa-eye-slash': !showPassword1, }"></i></span>

                </b-form-group>
                <div class="mt-3">
                    <button type="button" class="btn btn-dark btn-lg btn-block" :disabled="this.$v.update_profile.$anyError || isDisabled" @click="resetPassword()">Reset
                        Password
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
    import API from '@/citizen_api'
    import Vue from "vue";
    import {helpers, required, minLength, email, between, sameAs, requiredIf} from "vuelidate/lib/validators";
    import {validationMixin} from "vuelidate";

    export default {
        mixins: [validationMixin],
        name: 'Forget-Password',
        components: {
            // FormWizard, TabContent
        },
        data() {
            return {
                form: {
                    email: '',
                    client_url: window.location.href.split('#')[0],
                },
                update_profile: {
                    otp: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    client_url: window.location.href.split('#')[0],
                },
                showPassword: true,
                showPassword1: true,
                inputType1: 'password',
                inputType: 'password',
                isDisabled: false,
                title: 'Forget Password',
                step: 1,
                showDismissibleAlert: false,
                alertMessage: '',
                variant: 'danger',
            };
        },
        validations: {
            form: {
                email: {
                    required
                },
            },
            update_profile: {
                otp: {
                    required
                },
                email: {
                    required
                },
                password: {
                    required: required,
                    minLength: minLength(6),
                    // valid: function (value) {
                    //     const containsUppercase = /[A-Z]/.test(value)
                    //     const containsLowercase = /[a-z]/.test(value)
                    //     const containsNumber = /[0-9]/.test(value)
                    //     const containsSpecial = /[#?!@$%^&*-]/.test(value)
                    //     return containsUppercase && containsLowercase && containsNumber && containsSpecial
                    // },

                },
                password_confirmation: {
                    sameAsPassword: sameAs('password')
                }
            }
        },
        computed: {},
        beforeMount() {
        },
        mounted() {
        },
        created() {
            this.$EventBus.$on("openForgetPassword", (data) => {
                this.openForgetPassword();
            });
        },
        methods: {
            openForgetPassword() {
                this.$root.$emit("bv::show::modal", "modal-forgetPassword");
                // this.getAppSetting();
                this.$v.form.$reset();
                this.isDisabled = false;
                this.form.email = '';
            },
            forgetPassword() {
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }
                this.isDisabled = true;
                API.forgetSpotterPassword(
                    this.form,
                    data => {
                        this.isDisabled = false;
                        console.log(data);
                        if (data.status === 400) {
                            this.showDismissibleAlert = true;
                            this.variant = 'danger';
                            this.alertMessage = data.message;
                        } else {

                            this.update_profile.email = '';
                            this.update_profile.otp = '';
                            this.update_profile.password = '';
                            this.update_profile.password_confirmation = '';

                            this.title = 'Reset Password';
                            this.step = 2;
                            this.showDismissibleAlert = true;
                            this.variant = 'success';
                            this.alertMessage = data.message;
                        }
                    }, err => {
                        this.isDisabled = false;
                    }
                )
            },
            resetPassword() {
                this.$v.update_profile.$touch();
                if (this.$v.update_profile.$anyError) {
                    return;
                }
                this.isDisabled = true;
                API.resetSpotterPassword(
                    this.update_profile,
                    data => {
                        this.isDisabled = false;
                        if (data.status === 400) {
                            this.showDismissibleAlert = true;
                            this.variant = 'danger';
                            this.alertMessage = data.message;
                        } else {
                            this.title = 'Forget Password';
                            this.step = 1;
                            this.showDismissibleAlert = false;
                            this.variant = 'danger';
                            this.alertMessage = '';
                            this.$root.$emit("bv::hide::modal", "modal-forgetPassword");
                        }
                    }, err => {
                        this.isDisabled = false;
                    }
                )
            },
            toggleConfirmShow() {
                this.showPassword1 = !this.showPassword1;
                if (this.showPassword1) {
                    this.inputType1 = 'password';
                } else {
                    this.inputType1 = 'text';
                }
            },
            toggleShow() {
                this.showPassword = !this.showPassword;
                if (this.showPassword) {
                    this.inputType = 'password';
                } else {
                    this.inputType = 'text';
                }
            },
        }
    };
</script>

