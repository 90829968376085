import Vue from "vue";
import Router from "vue-router";

import layout from "../layout";
import citizen_layout from "../citizen_layout";
import full_screen_layout from "../full_screen_layout";
import API from '@/api';

Vue.use(Router);

const router = new Router({
    linkExactActiveClass: "active",
    scrollBehavior: () => ({y: 0}),
    mode: "history",
    base: "/",
    routes: [
        {
            path: "/project/map/:id",
            component: full_screen_layout,
            children: [
                {
                    path: "",
                    name: "full_screen_map",
                    component: () => import("@/citizen/full_screen_map")
                },
            ]
        },
        {
            path: "/project/:id",
            component: citizen_layout,
            children: [
                {
                    path: "",
                    name: "citizen",
                    component: () => import("@/citizen/dashboard")
                },
            ]
        },
        {
            path: "/data/collection/questions",
            component: layout,
            children: [
                {
                    path: "",
                    name: "questions",
                    component: () => import("@/client/data_collection_questions")
                }
            ]
        },
        {
            path: "/data/post-process/questions",
            component: layout,
            children: [
                {
                    path: "",
                    name: "questions",
                    component: () => import("@/client/post_process_data")
                }
            ]
        },
        // {
        //     path: "/survey/settings",
        //     component: layout,
        //     children: [
        //         {
        //             path: "",
        //             name: "surveys",
        //             component: () => import("@/client/survey_settings")
        //         }
        //     ]
        // },
        {
            path: "/surveys",
            component: layout,
            children: [
                {
                    path: "",
                    name: "surveys",
                    component: () => import("@/client/Survey")
                }
            ]
        },
        {
            path: "/survey/fields",
            component: layout,
            children: [
                {
                    path: "",
                    name: "surveys",
                    component: () => import("@/client/customFields")
                }
            ]
        },
        {
            path: "/survey/summary",
            component: layout,
            children: [
                {
                    path: "",
                    name: "surveys",
                    component: () => import("@/client/surveySummary")
                }
            ]
        },
        {
            path: "/weather",
            component: layout,
            children: [
                {
                    path: "",
                    name: "weather",
                    component: () => import("@/client/AdminWeather")
                }
            ]
        },
        {
            path: "/spots",
            component: layout,
            children: [
                {
                    path: "",
                    name: "spots",
                    component: () => import("@/client/spots")
                }
            ]
        },
        {
            path: "/add_spot",
            component: layout,
            children: [
                {
                    path: "",
                    name: "add_spot",
                    component: () => import("@/client/add_spot")
                }
            ]
        },
        {
            path: "/import_spots",
            component: layout,
            children: [
                {
                    path: "",
                    name: "import_spots",
                    component: () => import("@/client/import_spots")
                }
            ]
        },
        {
            path: "/upload_spot_images",
            component: layout,
            children: [
                {
                    path: "",
                    name: "upload_spot_images",
                    component: () => import("@/client/upload_spot_images")
                }
            ]
        },
        {
            path: "/edit_Spot/:id/:type",
            component: layout,
            children: [
                {
                    path: "",
                    name: "edit_spots",
                    component: () => import("@/client/edit_spots")
                }
            ]
        },
        {
            path: "/satellite_map",
            component: layout,
            children: [
                {
                    path: "",
                    name: "satellite_map",
                    component: () => import("@/client/satellite_map")
                }
            ]
        },
        {
            path: "/heat/map",
            component: layout,
            children: [
                {
                    path: "",
                    name: "heat_map",
                    component: () => import("@/client/heat_map")
                }
            ]
        },
        {
            path: "/bathymetry/map",
            component: layout,
            children: [
                {
                    path: "",
                    name: "BathymetryMap",
                    component: () => import("@/client/BathymetryMap")
                }
            ]
        },
        {
            path: "/edit_spotter/:id",
            component: layout,
            children: [
                {
                    path: "",
                    name: "editSpotter",
                    component: () => import("@/client/edit_spotter")
                }
            ]
        },
        {
            path: "/add_spotter",
            component: layout,
            children: [
                {
                    path: "",
                    name: "addSpotter",
                    component: () => import("@/client/edit_spotter")
                }
            ]
        },
        {
            path: "/spotter",
            component: layout,
            children: [
                {
                    path: "",
                    name: "spotters",
                    component: () => import("@/client/spotter")
                }
            ]
        },
        {
            path: "/spotter/notifications",
            component: layout,
            children: [
                {
                    path: "",
                    name: "notifications",
                    component: () => import("@/client/notifications")
                }
            ]
        },
        {
            path: "/spotter/welcome",
            component: layout,
            children: [
                {
                    path: "",
                    name: "spotter_welcome",
                    component: () => import("@/client/welcome")
                }
            ]
        },
        {
            path: "/spotter/dashboard",
            component: layout,
            children: [
                {
                    path: "",
                    name: "spotter_dashboard",
                    component: () => import("@/client/spotter_dashboard")
                }
            ]
        },
        // {
        //     path: "/app/settings",
        //     component: layout,
        //     children: [
        //         {
        //             path: "",
        //             name: "app_settings",
        //             component: () => import("@/client/app_settings")
        //         }
        //     ]
        // },
        // {
        //     path: "/app/system/settings",
        //     component: layout,
        //     children: [
        //         {
        //             path: "",
        //             name: "system_settings",
        //             component: () => import("@/client/system_settings")
        //         }
        //     ]
        // },
        // {
        //     path: "/app/system/settings",
        //     component: layout,
        //     children: [
        //         {
        //             path: "",
        //             name: "system_app_settings",
        //             component: () => import("@/client/system_app_settings")
        //         }
        //     ]
        // },
        {
            path: "/app/share/settings",
            component: layout,
            children: [
                {
                    path: "",
                    name: "share_settings",
                    component: () => import("@/client/share_settings")
                }
            ]
        },
        {
            path: "/app/map/settings",
            component: layout,
            children: [
                {
                    path: "",
                    name: "map_settings",
                    component: () => import("@/client/map_settings")
                }
            ]
        },
        {
            path: "/app/spotter/settings",
            component: layout,
            children: [
                {
                    path: "",
                    name: "spotter_settings",
                    component: () => import("@/client/spotter_settings")
                }
            ]
        },
        {
            path: "/app/branding/settings",
            component: layout,
            children: [
                {
                    path: "",
                    name: "brand_settings",
                    component: () => import("@/client/branding")
                }
            ]
        },
        {
            path: "/app/field_guide",
            component: layout,
            children: [
                {
                    path: "",
                    name: "field_guide",
                    component: () => import("@/client/field_guide")
                }
            ]
        },
        // {
        //     path: "/app/billing",
        //     component: layout,
        //     children: [
        //         {
        //             path: "",
        //             name: "settings",
        //             component: () => import("@/client/Billing")
        //         }
        //     ]
        // },
        {
            path: "/app/notification",
            component: layout,
            children: [
                {
                    path: "",
                    name: "settings",
                    component: () => import("@/client/email_notifications")
                }
            ]
        },
        {
            path: "/email/settings",
            component: layout,
            children: [
                {
                    path: "",
                    name: "email_settings",
                    component: () => import("@/client/email_settings")
                }
            ]
        },
        {
            path: "/spot/types",
            component: layout,
            children: [
                {
                    path: "",
                    name: "spot_type",
                    component: () => import("@/client/spot_type")
                }
            ]
        },
        {
            path: "/spot-types/archived",
            component: layout,
            children: [
                {
                    path: "",
                    name: "ArchivedSpotType",
                    component: () => import("@/client/ArchivedSpotType")
                }
            ]
        },
        {
            path: "/categories/archived",
            component: layout,
            children: [
                {
                    path: "",
                    name: "ArchivedSpotType",
                    component: () => import("@/client/ArchivedCategories")
                }
            ]
        },
        {
            path: "/surveys/archived",
            component: layout,
            children: [
                {
                    path: "",
                    name: "ArchivedSurveys",
                    component: () => import("@/client/ArchivedSurveys")
                }
            ]
        },
        {
            path: "/custom/fields/archived",
            component: layout,
            children: [
                {
                    path: "",
                    name: "ArchivedCustomFields",
                    component: () => import("@/client/ArchivedCustomFields")
                }
            ]
        },
        {
            path: "/locations",
            component: layout,
            children: [
                {
                    path: "",
                    name: "locations",
                    component: () => import("@/client/location_type")
                }
            ]
        },
        {
            path: "/fix_locations/:location_id",
            component: layout,
            children: [
                {
                    path: "",
                    name: "fix_locations",
                    component: () => import("@/client/FixLocations")
                }
            ]
        },
        {
            path: "/locations/archived",
            component: layout,
            children: [
                {
                    path: "",
                    name: "ArchivedLocations",
                    component: () => import("@/client/ArchivedLocations")
                }
            ]
        },
        {
            path: "/categories",
            component: layout,
            children: [
                {
                    path: "",
                    name: "categories",
                    component: () => import("@/client/category")
                }
            ]
        },
        {
            path: "/clients",
            component: layout,
            children: [
                {
                    path: "",
                    name: "clients",
                    component: () => import("@/SuperAdmin/clients")
                }
            ]
        },
        // {
        //     path: "/settings",
        //     component: layout,
        //     children: [
        //         {
        //             path: "",
        //             name: "settings",
        //             component: () => import("@/SuperAdmin/EditProfileForm")
        //         }
        //     ]
        // },
        {
            path: "/settings",
            component: layout,
            children: [
                {
                    path: "",
                    name: "settings",
                    component: () => import("@/SuperAdmin/settings")
                }
            ]
        },
        {
            path: "/dashboard",
            component: layout,
            children: [
                {
                    path: "",
                    name: "dashboard",
                    component: () => import("@/SuperAdmin/dashboard")
                }
            ]
        },
        {
            path: "/edit_client/:id",
            component: layout,
            children: [
                {
                    path: "",
                    name: "editclient",
                    component: () => import("@/SuperAdmin/add_edit_client")
                }
            ]
        },
        {
            path: "/user/users",
            component: layout,
            children: [
                {
                    path: "",
                    name: "users",
                    component: () => import("@/SuperAdmin/users")
                }
            ]
        },
        {
            path: "/user/roles",
            component: layout,
            children: [
                {
                    path: "",
                    name: "roles",
                    component: () => import("@/SuperAdmin/roles")
                }
            ]
        },
        {
            path: "/add_client",
            component: layout,
            children: [
                {
                    path: "",
                    name: "addclient",
                    component: () => import("@/SuperAdmin/add_edit_client")
                }
            ]
        },
        {
            path: "/dashboard",
            component: layout,
            children: [
                {
                    path: "",
                    name: "dashboard",
                    component: () => import("@/pages/dashboard")
                }
            ]
        },
        {
            path: "/",
            component: {
                render(c) {
                    return c("router-view");
                }
            },
            children: [
                {
                    path: "",
                    name: "log",
                    component: () => import("@/pages/samples/auth-pages/login")
                },
                {
                    path: "/forget-password",
                    name: "forget-password",
                    component: () => import("@/SuperAdmin/forget_password")
                },
                {
                    path: "/reset-password/:token",
                    name: "reset-password",
                    component: () => import("@/SuperAdmin/reset_passwords")
                },
            ]
        },
        {
            path: "/reports",
            component: layout,
            children: [
                {
                    path: "",
                    name: "reports",
                    component: () => import("@/client/reports")
                }
            ]
        },
        {
            path: "/projects",
            component: layout,
            children: [
                {
                    path: "",
                    name: "projects",
                    component: () => import("@/client/project")
                }
            ]
        },
        {
            path: "/projects/setup",
            component: layout,
            children: [
                {
                    path: "",
                    name: "projects",
                    component: () => import("@/client/projectSetup")
                }
            ]
        },
        {
            path: "/projects/weather",
            component: layout,
            children: [
                {
                    path: "",
                    name: "projects",
                    component: () => import("@/client/weather")
                }
            ]
        },
        {
            path: "/projects/effort",
            component: layout,
            children: [
                {
                    path: "",
                    name: "projects",
                    component: () => import("@/client/effort")
                }
            ]
        },
        {
            path: "/projects/survey",
            component: layout,
            children: [
                {
                    path: "",
                    name: "projects",
                    component: () => import("@/client/sightingInformationPart1")
                }
            ]
        },
        {
            path: "/projects/sighting",
            component: layout,
            children: [
                {
                    path: "",
                    name: "projects",
                    component: () => import("@/client/sightingInformationPart2")
                }
            ]
        },
        {
            path: "/projects/reports",
            component: layout,
            children: [
                {
                    path: "",
                    name: "projects",
                    component: () => import("@/client/prosync_repots")
                }
            ]
        },
        {
            path: "/projects/settings",
            component: layout,
            children: [
                {
                    path: "",
                    name: "projects",
                    component: () => import("@/client/settings")
                }
            ]
        },
        {
            path: "/view_report",
            component: layout,
            children: [
                {
                    path: "",
                    name: "ReportView",
                    component: () => import("@/client/ReportView")
                }
            ]
        },
        {
            path: "/chat",
            component: layout,
            children: [
                {
                    path: "",
                    name: "chat",
                    component: () => import("@/client/chat")
                }
            ]
        },
        // {
        //     path: "/chat2",
        //     component: layout,
        //     children: [
        //         {
        //             path: "",
        //             name: "chat2",
        //             component: () => import("@/components/chat/Chat")
        //         }
        //     ]
        // },
        {
            path: "/error-pages",
            component: {
                render(c) {
                    return c("router-view");
                }
            },
            children: [
                {
                    path: "error-404",
                    name: "error-404",
                    component: () => import("@/pages/samples/error-pages/error-404")
                },
                {
                    path: "error-500",
                    name: "error-500",
                    component: () => import("@/pages/samples/error-pages/error-500")
                }
            ]
        },
        {
            path: "*",
            redirect: "/error-404",
            component: {
                render(c) {
                    return c("router-view");
                }
            },
            children: [
                {
                    path: "error-404",
                    component: () => import("@/pages/samples/error-pages/error-404")
                }
            ]
        },
        {
            path: "/deleteSpotter/:client_id",
            name: "SpotterDelete",
            component: () => import("@/SuperAdmin/mobile/SpotterDelete"),
            meta: {
                requiresAuth: false 
            },
            props: true
        }
    ]
});

export default router
router.beforeEach((to, from, next) => {
    if (to.path.startsWith('/deleteSpotter/')) {
        next();
    }else {
        let email = localStorage.getItem('email')
    let password = localStorage.getItem('password')
    if (email !== null && password !== null && localStorage.getItem('isLoggedin') == null) {
        let data = {
            email: email,
            password: password,
        }

        API.login(
            data,
            data => {
                if (typeof data.token !== "undefined") {
                    localStorage.setItem('isLoggedin', data.token)
                    localStorage.setItem('userPermissions', JSON.stringify(data.permission))
                    Vue.prototype.$session.set('isLoggedin', data.token);
                    Vue.prototype.$session.set('userPermissions', JSON.stringify(data.permission));
                    if (to.name == '') {
                        router.push("/dashboard");
                    } else {
                        router.push({name: to.name});
                    }

                }
            },
            err => {

            }
        )
    } else {
        if (next.name === 'forget-password' && to.name !== 'reset-password' && localStorage.getItem('isLoggedin') == null) {
            next({name: 'forget-password'})
        } else if (to.name !== 'log' && to.name !== 'citizen' && to.name !== 'full_screen_map' && to.name !== 'forget-password' && to.name !== 'reset-password' && localStorage.getItem('isLoggedin') == null) {
            next({name: 'log'})
        } else if (to.name == 'log' && localStorage.getItem('isLoggedin') !== null) {
            next({name: 'dashboard'})
        } else {
            next();
        }
    }
}
})
