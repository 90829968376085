<template>
    <div id="chartdiv5"></div>
</template>
<script>

    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";
    import API from '@/api'

    export default {
        props: ['date_from', 'date_to'],
        // inject: ["injected_date_from", "injected_date_to"],
        data() {
            return {
                form: {
                    date_from: '',
                    date_to: '',
                    client_id: '',
                },
            };
        },
        beforeMount() {
            this.form.date_from = this.date_from;
            this.form.date_to = this.date_to;
            this.selected_client_id = JSON.parse(localStorage.getItem('client_id'));
            this.form.client_id = this.selected_client_id;
            this.getChartData();
        },
        mounted() {
        },
        created() {
            // this.$EventBus.$on("filterByDate", (data) => {
            //     this.getFormData(data);
            // });
            // this.$EventBus.$on("resetForm", (data) => {
            //     this.getFormData(data);
            // });
        },
        methods: {
            getFormData(data) {
                this.form.date_from = data.date_from;
                this.form.date_to = data.date_to;
                this.updateChartData()
            },
            getChartData() {
                // this.injected_date_from;
                // console.log(' this.injected_date_from :',  this.injected_date_from);
                // this.injected_date_to;
                // console.log(' this.injected_date_to :',  this.injected_date_to);
                this.form.date_from = this.$parent.form.date_from;   
                this.form.date_to = this.$parent.form.date_to;    
                API.getSpotPerMonth(this.form,
                    data => {
                        var sportArray = data.data.spotArr
                        am4core.useTheme(am4themes_animated);
                        let chart = am4core.create("chartdiv5", am4charts.XYChart);
                        if(chart.logo){
                            chart.logo.disabled = true;
                        }
                        chart.data = sportArray;
                        console.log('ChartData',chart.data);
                        // Create axes

                        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                        categoryAxis.dataFields.category = "month";
                        categoryAxis.renderer.grid.template.location = -12;
                        categoryAxis.renderer.minGridDistance = 30;

                        // categoryAxis.renderer.labels.template.adapter.add("dy", function(dy, target) {
                        // if (target.dataItem && target.dataItem.index & 2 == 2) {
                        //     return dy + 25;
                        // }
                        // return dy;
                        // });

                        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

                        // Create series
                        let series = chart.series.push(new am4charts.ColumnSeries());
                        series.dataFields.valueY = "count";
                        series.dataFields.categoryX = "month";
                        // chart.events.on("ready", function(ev){
                        //     hideIndicator();
                        // });

                        // let indicator;
                        // let indicatorInterval;

                        // function showIndicator() {
                        // if (!indicator) {
                        //     indicator = chart.tooltipContainer.createChild(am4core.Container);
                        //     indicator.background.fill = am4core.color("#fff");
                        //     indicator.width = am4core.percent(100);
                        //     indicator.height = am4core.percent(100);

                        //     var indicatorLabel = indicator.createChild(am4core.Label);
                        //     indicatorLabel.text = "Loading ...";
                        //     indicatorLabel.align = "center";
                        //     indicatorLabel.valign = "middle";
                        //     indicatorLabel.dy = 50;
                            
                        //     var hourglass = indicator.createChild(am4core.Image);
                        //     hourglass.href = "https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-160/hourglass.svg";
                        //     hourglass.align = "center";
                        //     hourglass.valign = "middle";
                        //     hourglass.horizontalCenter = "middle";
                        //     hourglass.verticalCenter = "middle";
                        //     hourglass.scale = 0.4;
                        // }

                        // indicator.hide(0);
                        // indicator.show();

                        // clearInterval(indicatorInterval);
                        // indicatorInterval = setInterval(function() {
                        //     hourglass.animate([{
                        //     from: 0,
                        //     to: 360,
                        //     property: "rotation"
                        //     }], 2000);
                        // }, 3000);
                        // }

                        // function hideIndicator() {
                        // indicator.hide();
                        // clearInterval(indicatorInterval);
                        // }

                        // showIndicator();
                        series.name = "Counts";
                        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
                        series.columns.template.fillOpacity = .8;

                        let columnTemplate = series.columns.template;
                        columnTemplate.strokeWidth = 2;
                        columnTemplate.strokeOpacity = 1;
                        chart.responsive.enabled = true;

                    },
                    err => {
                    }
                )
            },
            updateChartData() {
                API.getSpotPerMonth(this.form,
                    data => {
                        var sportArray = data.data.spotArr
                        am4core.useTheme(am4themes_animated);
                        let chart = am4core.create("chartdiv5", am4charts.XYChart);
                        if(chart.logo){
                            chart.logo.disabled = true;
                        }
                        chart.data = sportArray;
                        console.log('ChartData',chart.data);
                        // Create axes

                        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                        categoryAxis.dataFields.category = "month";
                        categoryAxis.renderer.grid.template.location = -12;
                        categoryAxis.renderer.minGridDistance = 30;

                        // categoryAxis.renderer.labels.template.adapter.add("dy", function(dy, target) {
                        // if (target.dataItem && target.dataItem.index & 2 == 2) {
                        //     return dy + 25;
                        // }
                        // return dy;
                        // });

                        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

                        // Create series
                        let series = chart.series.push(new am4charts.ColumnSeries());
                        series.dataFields.valueY = "count";
                        series.dataFields.categoryX = "month";
                        // chart.events.on("ready", function(ev){
                        //     hideIndicator();
                        // });

                        // let indicator;
                        // let indicatorInterval;

                        // function showIndicator() {
                        // if (!indicator) {
                        //     indicator = chart.tooltipContainer.createChild(am4core.Container);
                        //     indicator.background.fill = am4core.color("#fff");
                        //     indicator.width = am4core.percent(100);
                        //     indicator.height = am4core.percent(100);

                        //     var indicatorLabel = indicator.createChild(am4core.Label);
                        //     indicatorLabel.text = "Loading ...";
                        //     indicatorLabel.align = "center";
                        //     indicatorLabel.valign = "middle";
                        //     indicatorLabel.dy = 50;
                            
                        //     var hourglass = indicator.createChild(am4core.Image);
                        //     hourglass.href = "https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-160/hourglass.svg";
                        //     hourglass.align = "center";
                        //     hourglass.valign = "middle";
                        //     hourglass.horizontalCenter = "middle";
                        //     hourglass.verticalCenter = "middle";
                        //     hourglass.scale = 0.4;
                        // }

                        // indicator.hide(0);
                        // indicator.show();

                        // clearInterval(indicatorInterval);
                        // indicatorInterval = setInterval(function() {
                        //     hourglass.animate([{
                        //     from: 0,
                        //     to: 360,
                        //     property: "rotation"
                        //     }], 2000);
                        // }, 3000);
                        // }

                        // function hideIndicator() {
                        // indicator.hide();
                        // clearInterval(indicatorInterval);
                        // }

                        // showIndicator();
                        series.name = "Counts";
                        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
                        series.columns.template.fillOpacity = .8;

                        let columnTemplate = series.columns.template;
                        columnTemplate.strokeWidth = 2;
                        columnTemplate.strokeOpacity = 1;
                        chart.responsive.enabled = true;

                    },
                    err => {
                    }
                )
            },

        }
    };
</script>

<style>
#chartdiv5 {
    width: 100%;
    height: 330px;
  }
</style>