<template>

    <b-navbar id="template-header" :class="{ openSidebar: active, 'expanded': isActive }"
              class="left-head-sidebar navbar default-layout-navbar p-0 fixed-top" toggleable="lg">
        <div v-if="show_menu" class="head-burgermenu">
            <button type="button" class="toggle-button" @click="active = !active" :aria-pressed="active ? 'true' : 'false'">
                <i class="fa fa-bars"></i>
            </button>
        </div>
        <div class="horizontal-menu">
            <nav class="bottom-navbar">
                <div class="container">
                    <div class="site-logo">
                        <img :src="this.$server_url+logoImage" alt="logo"/>
                    </div>
                    <ul class="nav page-navigation">
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link" @click="home">
                                <span class="link-icons"><i class="fa-solid fa-house-chimney"></i></span>
                                <span class="menu-title">{{ $t('home') }}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link" @click="viewFieldGuide">
                                <span class="link-icons"><i class="fa-solid fa-file-circle-exclamation"></i></span>
                                <span class="menu-title">{{ $t('field-guide') }}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link add_spot" @click="recentSpots">
                                <span class="link-icons"><i class="fa-solid fa-location-dot"></i></span>
                                <span class="menu-title">{{ $t('recent-spots') }}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link add_spot" @click="addSpotModal">
                                <span class="link-icons"><i class="fa-solid fa-map-location-dot"></i></span>
                                <span class="menu-title">{{ $t('add-a-spot') }}</span>
                                <span class="lock-icon"><i class="fa-solid fa-lock" v-if="!isLoggedIn"></i></span>
                            </a>
                        </li>
                        <li class="nav-item" >
                            <a href="javascript:void(0)" class="nav-link" @click="showAnalytics">
                                <span class="link-icons"><i class="fa-solid fa-chart-pie"></i></span>
                                <span class="menu-title">{{ $t('analytics') }}</span>
                                <span class="lock-icon"><i class="fa-solid fa-lock" v-if="!isLoggedIn"></i></span>
                            </a>
                        </li>
                        <!-- <li class="nav-item" >
                            <a href="javascript:void(0)" class="nav-link" @click="showMessaging">
                                <span class="link-icons"><i class="fas fa-comment-dots"></i></span>
                                <span class="menu-title">{{ $t('messaging') }}</span>
                                <span class="lock-icon"><i class="fa-solid fa-lock" v-if="!isLoggedIn"></i></span>
                            </a>
                        </li> -->
<!--                        <li class="nav-item">-->
<!--                            <a href="javascript:void(0)" class="nav-link" @click="showAnalytics">-->
<!--                                <span class="link-icons"><i class="fa-brands fa-slack"></i></span>-->
<!--                                <span class="menu-title">Messaging</span>-->
<!--                                <span class="lock-icon"><i class="fa-solid fa-lock" v-if="!isLoggedIn"></i></span>-->
<!--                            </a>-->
<!--                        </li>-->
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link" @click="spotterSettings">
                                <span class="link-icons"><i class="fa-solid fa-gear"></i></span>
                                <span class="menu-title">{{ $t('settings') }}</span>
                                <span class="lock-icon"><i class="fa-solid fa-lock" v-if="!isLoggedIn"></i></span>
                            </a>
                        </li>
                        <li class="nav-item" v-if="!isLoggedIn">
                            <a href="javascript:void(0)" class="nav-link" v-b-modal.modal-login>
                                <span class="link-icons"><i class="fa-solid fa-right-to-bracket"></i></span>
                                <span class="menu-title">{{ $t('sign-in-register') }}</span></a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link" @click="isActive = !isActive" :aria-pressed="isActive ? 'true' : 'false'">
                                <span class="link-icons"><i class="fa-solid fa-expand"></i></span>
                                <span class="menu-title">{{ $t('expand') }}</span></a>
                        </li>
                        <li class="nav-item">
                            <a :href="form.client_url" class="nav-link" target="_blank">
                                <span class="link-icons"><i class="fa-solid fa-globe"></i></span>
                                <span class="menu-title">{{ $t('open-map') }}</span></a>
                        </li>
                        <li class="nav-item" v-if="isLoggedIn">
                            <button type="button" class="btn btn-sm btn-danger mt-3" @click="logout">{{ $t('logout') }}</button>
                        </li>
                    </ul>
                    <div class="sidebar-foot">
                        <p>{{ $t('powered-by') }}</p>
                        <div class="sidebarfoot-icon">
                            <a href="https://watchspotter.net/" target="_blank"><img src="@/assets/images/watch-logo.png" alt="logo"/></a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
        <b-modal id="modal-login" :title="$t('sign-in')" :hide-footer="true">
            <form class="pt-3">
                <b-form-group
                        id="input-group-1"
                        :label="$t('email-address')"
                        label-for="input-1"
                >
                    <b-form-input
                            id="input-1"
                            type="email"
                            :placeholder="$t('email-address*')"
                            v-model="$v.email.$model"
                            :state="$v.email.$dirty ? !$v.email.$error : null"
                            aria-describedby="input-2-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.email.required" id="input-2-live-feedback">
                        {{ $t("field-required") }}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback v-if="!$v.email.email" id="input-2-live-feedback">
                        Invalid email format
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                        id="input-group-2"
                        :label="$t('password')"
                        label-for="input-2"
                >
                                          <span class="icon is-small is-right"
                                                style="">
                                        <b-form-input
                                                id="input-2"
                                                :type="inputType"
                                                :placeholder="$t('password')"
                                                v-model="$v.password.$model"
                                                :state="$v.password.$dirty ? !$v.password.$error : null"
                                                aria-describedby="input-3-live-feedback"
                                        ></b-form-input>
                                         <b-form-invalid-feedback v-if="!$v.password.required"
                                                                  id="input-3-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
                                         <b-form-invalid-feedback v-if="!$v.password.minLength"
                                                                  id="input-3-live-feedback">Password must have at least {{ $v.password.$params.minLength.min }} letters.</b-form-invalid-feedback>
                                              <!--                                         <b-form-invalid-feedback v-if="password && !$v.password.valid" id="input-3-live-feedback">Password contains atleast One Uppercase, One Lowercase, One Number and One Special Character.</b-form-invalid-feedback>-->
                                      <i @click="toggleShow" class="fas password-icon"
                                         :class="{'fa fa-eye': showPassword, 'fa fa-eye-slash': !showPassword, }"></i></span>

                </b-form-group>

                <div class="mt-3">
                    <button type="button" class="btn btn-dark btn-lg btn-block"
                            :disabled="this.$v.email.$anyError || this.$v.password.$anyError"
                            @click="login()">
                       {{ $t('sign-in') }}
                    </button>
                </div>
                <div class="mt-3">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="col-md-6">
                                <a @click="signUp" href="javascript:void(0)" class="auth-link float-left text-info"> {{ $t('sign-up') }} </a>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <a @click="forgetPassword" href="javascript:void(0)"
                               class="ml-5 auth-link float-right text-info"> {{ $t('forgot-password') }}</a>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>
        <b-modal id="modal-signup" title="Sign Up" :hide-footer="true">
            <form class="pt-3">
                <b-form-group
                        id="input-group-1"
                        :label="$t('username*')"
                        label-for="input-1"
                >
                    <b-form-input
                            id="input-1"
                            type="text"
                            :placeholder="$t('username-placeholder')"
                            autocomplete="new-password"
                            v-model="$v.form.user_name.$model"
                            :state="$v.form.user_name.$dirty ? !$v.form.user_name.$error : null"
                            aria-describedby="input-2-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-2-live-feedback">
                        {{ $t("field-required") }}
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                        id="input-group-1"
                        :label="$t('your-name*')"
                        label-for="input-1"
                >
                    <b-form-input
                            id="input-1"
                            type="text"
                            :placeholder="$t('name')"
                            autocomplete="new-password"
                            v-model="$v.form.name.$model"
                            :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                            aria-describedby="input-2-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.form.name.required" id="input-2-live-feedback">
                        {{ $t("field-required") }}
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                        id="input-group-1"
                        :label="$t('email-address*')"
                        label-for="input-1"
                >
                    <b-form-input
                            id="input-1"
                            type="email"
                            autocomplete="new-password"
                            :placeholder="$t('email-address')"
                            v-model="$v.form.email.$model"
                            :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
                            aria-describedby="input-2-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.form.email.required" id="input-2-live-feedback">
                       {{ $t("field-required") }}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback v-if="!$v.form.email.email" id="input-2-live-feedback">
                        {{ $t("Enter a valid Email.") }}
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                        id="input-group-2"
                        :label="$t('password*')"
                        label-for="input-2"
                >
                                          <span class="icon is-small is-right"
                                                style="">
                                        <b-form-input
                                                id="input-2"
                                                :type="inputType"
                                                :placeholder="$t('password')"
                                                autocomplete="new-password"
                                                v-model="$v.form.password.$model"
                                                :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
                                                aria-describedby="input-3-live-feedback"
                                        ></b-form-input>
                                         <b-form-invalid-feedback v-if="!$v.form.password.required"
                                                                  id="input-3-live-feedback">{{ $t("field-required") }}.</b-form-invalid-feedback>
                                         <b-form-invalid-feedback v-if="!$v.form.password.minLength"
                                                                  id="input-3-live-feedback">Password must have at least {{ $v.form.password.$params.minLength.min }} letters.</b-form-invalid-feedback>
                                                                                       <b-form-invalid-feedback
                                                                                               v-if="form.password && !$v.form.password.valid"
                                                                                               id="input-3-live-feedback">{{ $t("Password contains atleast One Uppercase, One Lowercase, One Number and One Special Character.") }}</b-form-invalid-feedback>
                                      <i @click="toggleShow" class="fas password-icon"
                                         :class="{'fa fa-eye': showPassword, 'fa fa-eye-slash': !showPassword, }"></i></span>

                </b-form-group>
                <div class="row" v-if="trainingRequired">
                    <div class="col-md-6">
                        <b-form-group :label="$t('completed-training')" label-for="approval_date">
                            <b-form-select v-model="form.training_status"
                                           :options="trainingOptions"></b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group :label="$t('date-completed')" label-for="date_completed">
                            <b-form-input
                                    type="date"
                                    id="date_completed"
                                    name="date_completed"
                                    autocomplete="new-password"
                                    placeholder="Date Completed"
                                    v-model="form.date_completed"
                            >
                            </b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <b-form-group
                        id="mobile_phone"
                        :label="$t('call-phone*')"
                        label-for="mobile_phone"
                >
                    <b-form-input
                            id="mobile_phone"
                            type="text"
                            placeholder="Cell Phone"
                            v-model="$v.form.mobile_phone.$model"
                            :state="$v.form.mobile_phone.$dirty ? !$v.form.mobile_phone.$error : null"
                            aria-describedby="input-4-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.form.mobile_phone.required"
                                             id="input-3-live-feedback">{{ $t("field-required") }}
                    </b-form-invalid-feedback>
                </b-form-group>
                <!--                <b-form-group-->
                <!--                        id="address"-->
                <!--                        label="Location"-->
                <!--                        label-for="address"-->
                <!--                >-->
                <!--                    <b-form-input-->
                <!--                            id="address"-->
                <!--                            type="text"-->
                <!--                            placeholder="Cit/ Town Where you live"-->
                <!--                            v-model="form.address"-->
                <!--                    ></b-form-input>-->
                <!--                </b-form-group>-->
                <b-form-group :label="$t('date-of-birth')" label-for="dob">
                    <b-form-input
                            type="date"
                            id="dob"
                            name="dob"
                            autocomplete="new-password"
                            :placeholder="$t('date-of-birth')"
                            v-model="form.dob"
                    >
                    </b-form-input>
                </b-form-group>
                <div class="mt-3">
                    <button type="button" class="btn btn-dark btn-lg btn-block"
                            :disabled="isDisabled || $v.form.$anyError" @click="register()">
                        Register
                    </button>
                </div>
                <div class="mt-3">
                    <div class="row">
                        <div class="col-md-6">
                        </div>
                        <div class="col-md-6">
                            <a href="javascript:void(0)" @click="displayLogin"
                               class="ml-5 auth-link float-right text-info">Login</a>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>
        <b-modal id="modal-settings" :title="$t('profile-settings')" :hide-footer="true">
            <form class="pt-3">
                <b-form-group
                        id="input-group-1"
                        :label="$t('username*')"
                        label-for="input-1"
                >
                    <b-form-input
                            id="input-1"
                            type="text"
                            :placeholder="$t('username-placeholder')"
                            autocomplete="new-password"
                            v-model="$v.update_profile.user_name.$model"
                            :state="$v.update_profile.user_name.$dirty ? !$v.update_profile.user_name.$error : null"
                            aria-describedby="input-2-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-2-live-feedback">
                        {{ $t("field-required") }}
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                        id="input-group-1"
                        :label="$t('your-name*')"
                        label-for="input-1"
                >
                    <b-form-input
                            id="input-1"
                            type="text"
                            :placeholder="$t('name-placeholder')"
                            autocomplete="new-password"
                            v-model="$v.update_profile.name.$model"
                            :state="$v.update_profile.name.$dirty ? !$v.update_profile.name.$error : null"
                            aria-describedby="input-2-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.update_profile.name.required" id="input-2-live-feedback">
                        {{ $t("field-required") }}
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                        id="input-group-1"
                        :label="$t('email-address*')"
                        label-for="input-1"
                >
                    <b-form-input
                            id="input-1"
                            type="email"
                            autocomplete="new-password"
                            :placeholder="$t('email-address')"
                            v-model="$v.update_profile.email.$model"
                            :state="$v.update_profile.email.$dirty ? !$v.update_profile.email.$error : null"
                            aria-describedby="input-2-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.update_profile.email.required" id="input-2-live-feedback">
                        {{ $t("field-required") }}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback v-if="!$v.update_profile.email.email" id="input-2-live-feedback">
                        Invalid email format
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                        id="input-group-2"
                        :label="$t('password')"
                        label-for="input-2"
                >
                                          <span class="icon is-small is-right"
                                                style="">
                                        <b-form-input
                                                id="input-2"
                                                :type="inputType"
                                                :placeholder="$t('password')"
                                                autocomplete="new-password"
                                                v-model="$v.update_profile.password.$model"
                                                :state="$v.update_profile.password.$dirty ? !$v.update_profile.password.$error : null"
                                                aria-describedby="input-3-live-feedback"
                                        ></b-form-input>
                                         <b-form-invalid-feedback v-if="!$v.update_profile.password.required"
                                                                  id="input-3-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
                                         <b-form-invalid-feedback v-if="!$v.update_profile.password.minLength"
                                                                  id="input-3-live-feedback">Password must have at least {{ $v.update_profile.password.$params.minLength.min }} letters.</b-form-invalid-feedback>
                                                                                       <b-form-invalid-feedback
                                                                                               v-if="update_profile.password && !$v.update_profile.password.valid"
                                                                                               id="input-3-live-feedback">{{ $t("Password contains atleast One Uppercase, One Lowercase, One Number and One Special Character.") }}</b-form-invalid-feedback>
                                      <i @click="toggleShow" class="fas password-icon"
                                         :class="{'fa fa-eye': showPassword, 'fa fa-eye-slash': !showPassword, }"></i></span>

                </b-form-group>
                <b-form-group
                        id="input-group-4"
                        :label="$t('confirm-password')"
                        label-for="input-4"
                >
                                          <span class="icon is-small is-right"
                                                style="">
                                        <b-form-input
                                                id="input-4"
                                                autocomplete="new-password"
                                                :type="inputType1"
                                                :placeholder="$t('confirm-password')"
                                                v-model="$v.update_profile.password_confirmation.$model"
                                                :state="$v.update_profile.password_confirmation.$dirty ? !$v.update_profile.password_confirmation.$error : null"
                                                aria-describedby="input-4-live-feedback"
                                        ></b-form-input>
                                              <b-form-invalid-feedback
                                                      v-if="!$v.update_profile.password_confirmation.required"
                                                      id="input-3-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
                                         <b-form-invalid-feedback
                                                 v-if="!$v.update_profile.password_confirmation.sameAsPassword"
                                                 id="input-4-live-feedback">Passwords must be identical.</b-form-invalid-feedback>
                                      <i @click="toggleConfirmShow" class="fas password-icon"
                                         :class="{'fa fa-eye': showPassword1, 'fa fa-eye-slash': !showPassword1, }"></i></span>

                </b-form-group>
                <b-form-group
                        id="mobile_phone"
                        :label="$t('cell-phone*')"
                        label-for="mobile_phone"
                >
                    <b-form-input
                            id="mobile_phone"
                            type="text"
                            :placeholder="$t('cell-phone*')"
                            v-model="$v.update_profile.mobile_phone.$model"
                            :state="$v.update_profile.mobile_phone.$dirty ? !$v.update_profile.mobile_phone.$error : null"
                            aria-describedby="input-4-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback
                            v-if="!$v.update_profile.mobile_phone.required"
                            id="input-3-live-feedback">{{ $t("field-required") }}
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group :label="$t('date-of-birth')" label-for="dob">
                    <b-form-input
                            type="date"
                            id="dob"
                            name="dob"
                            autocomplete="new-password"
                            :placeholder="$t('date-of-birth')"
                            v-model="update_profile.dob"
                    >
                    </b-form-input>
                </b-form-group>
                <div class="mt-3">
                    <button type="button" class="btn btn-dark btn-lg btn-block"
                            :disabled="isDisabled || $v.update_profile.$anyError" @click="updateSpotterProfile()">
                        {{ $t("update-profile-btn") }}
                    </button>
                </div>
                <!--                <div class="mt-3">-->
                <!--                    <div class="row">-->
                <!--                        <div class="col-md-6">-->
                <!--                        </div>-->
                <!--                        <div class="col-md-6">-->
                <!--                            <a href="javascript:void(0)" @click="displayLogin"-->
                <!--                               class="ml-5 auth-link float-right text-info">Login</a>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
            </form>
        </b-modal>
        <!--                <fieldGuide></fieldGuide>-->
        <b-modal id="field-guide" :title="$t('field-guide')" size="md" :hide-footer="true">
            <div style="display:block;" class="field-guide" v-html="appSettingText"></div>
        </b-modal>

        <b-modal id="recent-spots" class="recent-spots-modal" :title="$t('recent-spots')" size="md" :hide-footer="true">
            <div class="d-block">
                 <b-container fluid>
                    <vue-loadmore
                            :on-refresh="onRefresh"
                            :on-loadmore="onLoad"
                            :finished="finished">
                            <div v-for="(item, i) of list" :key="i" class="full-width mt-3">
                                <!-- <div class="full-width mt-3"> -->
                                    <div class="card mt-2">
                                    
                                        <div class="row m-row d-flex">
                                            <div class="col-md-4 col-sm-12 md-img">
                                                <img class="img-fluid" v-if="item.primary_image && !checkImageType(item)" :src="serverUrl+item.primary_image" height="150" width="200" alt="Card Image">
                                                <img v-else-if="!checkImageType(item)" :src="defaultImage" height="150" width="180" alt="Card Image"/>
                                                <img v-else :src="defaultImage" height="150" width="180">
                                            </div>
                                            <div class="col-md-8 col-sm-12 new-col-modal p-2">
                                                <div class="row w-100">
                                                                        <div class="col-md-4 col-sm-4 col-xs-6">
                                                                                {{ $t('spot-id') }}
                                                                        </div>
                                                                        <div class="col-md-8 col-sm-8 col-xs-6" >
                                                                            <a href="javascript:void(0)" @click="openSpotDetail(item.id)"> {{ item.spot_id }} </a>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row w-100">
                                                                        <div class="col-md-4 col-sm-4 col-xs-6">
                                                                            {{ $t('spot-type') }}
                                                                        </div>
                                                                        <div class="col-md-8 col-sm-8 col-xs-6">
                                                                            {{ item.spot_type ? item.spot_type.name : '' }}
                                                                        </div>
                                                                    </div>
                                                                    <div class="row w-100">
                                                                        <div class="col-md-4 col-sm-4 col-xs-6">
                                                                            {{ $t('location') }}
                                                                        </div>
                                                                        <div class="col-md-8 col-sm-8 col-xs-6">
                                                                            {{ item.location ? item.location.name : '' }}
                                                                        </div>
                                                                    </div>
                                                                    <div class="row w-100">
                                                                        <div class="col-md-4 col-sm-4 col-xs-6">
                                                                            {{ $t('spot-date') }}
                                                                        </div>
                                                                        <div class="col-md-8 col-sm-8 col-xs-6">
                                                                            {{ formatDateMM(item.spot_date) }}
                                                                        </div>
                                                                    </div>
                                                                    <div class="row w-100">
                                                                        <div class="col-md-4 col-sm-4 col-xs-6">
                                                                            {{ $t('details') }}
                                                                        </div>
                                                                        <div class="col-md-8 col-sm-8 col-xs-6" v-snip="3">
                                                                        <p>{{ item.detail }}</p>
                                                                        </div>
                                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                <!-- </div> -->
                            </div>
                    </vue-loadmore>
            </b-container>
            </div>

        </b-modal>

        <SpotDetailsModal :showRecentSpot="true" ref="spotDetails"></SpotDetailsModal>
        <!-- <div class="sidebar-footer">
            <p class="text-center mb-0">Powered By <span><a href="https://watchspotter.net/" target="_blank">WatchSpotter</a></span>
            </p>
        </div> -->
        <AnalyticsDashboard/>
        <forget-password/>
    </b-navbar>
</template>

<script>
    import {validationMixin} from "vuelidate";
    import {helpers, required, minLength, email, sameAs, requiredIf} from "vuelidate/lib/validators";
    import router from "../../router";
    import API from '@/citizen_api'
    import Vue from "vue";
    // import fieldGuide from "../../citizen/fieldGuide";
    import moment from 'moment';
    import AnalyticsDashboard from "../../citizen/analytics_dashboard";
    import forgetPassword from "../../citizen/forgetPassword";
    // import { eventBus } from "../../main";
    import VueSnip from 'vue-snip'
    import SpotDetailsModal from '../../citizen/SpotDetailsModal.vue';
    Vue.use(VueSnip);
    export default {
        name: "app-header",
        mixins: [validationMixin],
        components: {
            forgetPassword,
            AnalyticsDashboard,
            SpotDetailsModal
        },
        data() {
            return {
                show_menu: null,
                showRecentSpot: true,
                version_id: null,
                email: '',
                password: '',
                disabled: '',
                remember_be: '',
                showPassword: true,
                appSetting: [],
                inputType: 'password',
                isDisabled: false,
                isLoggedIn: false,
                trainingRequired: false,
                // enable_acoustic: false,
                active: false,
                isActive: false,
                appSettingText: '',
                showPassword1: true,
                inputType1: 'password',
                update_profile: {
                    id: '',
                    name: '',
                    user_name: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    mobile_phone: '',
                    dob: '',
                    client_url: window.location.href.split('#')[0],
                },
                form: {
                    name: '',
                    user_name: '',
                    email: '',
                    password: '',
                    training_status: 1,
                    date_completed: '',
                    mobile_phone: '',
                    address: '',
                    dob: '',
                    client_url: '',
                },
                trainingOptions: {
                    1: 'Yes',
                    0: 'No',
                },
                list: [],
                mediaTypes: [],
                page: 1,
                pageSize: 10,
                lastPage: 10,
                serverUrl: '',
                defaultImage: '',
                defaultImage2: '',
                logoImage: '',
                finished: false,
                spotDetail: [],
                spotDetailImages: [],
                nickName: ''
            };
        },
        validations: {
            email: {
                email,
                required,
            }, password: {
                required,
                minLength: minLength(6),
            },
            form: {
                user_name: {
                    required
                },
                name: {
                    required
                },
                email: {
                    email,
                    required
                },
                mobile_phone: {
                    required
                },
                password: {
                    required: required,
                    minLength: minLength(6),
                    valid: function (value) {
                        const containsUppercase = /[A-Z]/.test(value)
                        const containsLowercase = /[a-z]/.test(value)
                        const containsNumber = /[0-9]/.test(value)
                        const containsSpecial = /[#?!@$%^&*-]/.test(value)
                        return containsUppercase && containsLowercase && containsNumber && containsSpecial
                    },

                },
            },
            update_profile: {
                user_name: {
                    required
                },
                name: {
                    required
                },
                email: {
                    email,
                    required
                },
                mobile_phone: {
                    required
                },
                password: {
                    required: requiredIf((value) => !value.id),
                    minLength: minLength(6),
                    // valid: function (value) {
                    //     const containsUppercase = /[A-Z]/.test(value)
                    //     const containsLowercase = /[a-z]/.test(value)
                    //     const containsNumber = /[0-9]/.test(value)
                    //     const containsSpecial = /[#?!@$%^&*-]/.test(value)
                    //     return containsUppercase && containsLowercase && containsNumber && containsSpecial
                    // },

                },
                password_confirmation: {
                    sameAsPassword: sameAs('password')
                }
            }
        },
        computed: {
            
        },
        created() {
            this.getAppSetting();
            window.addEventListener("scroll", this.handleSCroll);
        },
        beforeMount() {
            if (localStorage.getItem('spotterLoggedIn') == null || localStorage.getItem('spotterUrl') !== window.location.href.split('#')[0]) {
                this.isLoggedIn = false;
                this.logoutFn();
            } else {
                this.isLoggedIn = true;
            }
        },
        mounted() {
            this.$root.$on('logout-event', this.logout)
            // this.fetch();
            this.getPageTitle();
            var navItems = document.querySelectorAll(
                ".horizontal-menu .page-navigation >.nav-item"
            );
            document
                .querySelectorAll(".horizontal-menu .page-navigation >.nav-item")
                .forEach(function (el) {
                    el.addEventListener("click", function () {
                        for (var i = 0; i < navItems.length; i++) {
                            if (navItems[i] == el) {
                                el.classList.toggle("show-submenu");
                            } else {
                                navItems[i].classList.remove("show-submenu");
                            }
                        }
                    });
                });
        },
        methods: {
            checkImage(image) {
                let found = image.search('.mp3');
                if(found === -1){
                    return true;
                } else {
                    return false;
                }
            },
            toggleMobileSidebar: () => {
                document
                    .querySelector(".bottom-navbar")
                    .classList.toggle("header-toggled");
            },
            togglesettingsPannel: () => {
                document.querySelector("#right-sidebar").classList.toggle("open");
            },
            subIsActive(input) {
                const paths = Array.isArray(input) ? input : [input];
                return paths.some(path => {
                    return this.$route.path.indexOf(path) === 0; // current path starts with this path string
                });
            },
            handleSCroll() {
                let header = document.querySelector("body");
                if (window.scrollY > 70) {
                    header.classList.add("fixed-on-scroll");
                } else {
                    header.classList.remove("fixed-on-scroll");
                }
            },
            toggleShow() {
                this.showPassword = !this.showPassword;
                if (this.showPassword) {
                    this.inputType = 'password';
                } else {
                    this.inputType = 'text';
                }
            },
            signUp() {
                this.$root.$emit("bv::hide::modal", "modal-login");
                this.$root.$emit("bv::show::modal", "modal-signup");
            },
            displayLogin() {
                this.$root.$emit("bv::show::modal", "modal-login");
                this.$root.$emit("bv::hide::modal", "modal-signup");
            },
            toggleConfirmShow() {
                this.showPassword1 = !this.showPassword1;
                if (this.showPassword1) {
                    this.inputType1 = 'password';
                } else {
                    this.inputType1 = 'text';
                }
            },
            async getAppSetting() {
                const data = {
                    client_url: window.location.href.split('#')[0],
                }
                const res = await API.getAppSetting(
                    data,
                    data => {
                        // this.trainingRequired = (data.data.training_complete == 1) ? true : false;
                        // this.trainingRequired = (data.data.training_complete == 1) ? true : false;
                        // this.enable_acoustic = data.data.enable_acoustic;
                        this.show_menu = data.data.show_menu;
                        this.appSettingText = data.data.editor_content;
                        this.defaultImage = data.data.stock_image;
                        this.logoImage = data.data.logo;
                    },
                    err => {
                    }
                )
                return res;
            },
            updateSpotterProfile() {
                this.update_profile.client_url = window.location.href.split('#')[0];
                this.$v.update_profile.$touch();
                console.log(this.$v.update_profile.$anyError)
                if (this.$v.update_profile.$anyError) {
                    return;
                }

                this.isDisabled = true;
                API.updateSpotterProfile(
                    this.update_profile,
                    data => {
                        this.isDisabled = false;
                        if (data.status === 410) {
                            this.$swal({
                                type: 'error',
                                title: 'Oops...',
                                text: data.message,
                            })
                        } else {
                            this.isDisabled = false;
                            this.$root.$emit("bv::hide::modal", "modal-settings");
                        }

                    },
                    err => {
                        this.isDisabled = false;

                    }
                )
            },
            register() {
                this.form.client_url = window.location.href.split('#')[0];
                this.$v.form.$touch();
                console.log(this.$v.form.$anyError)
                if (this.$v.form.$anyError) {
                    return;
                }

                this.isDisabled = true;
                API.registerSpotter(
                    this.form,
                    data => {
                        this.isDisabled = false;
                        if (typeof data.token !== "undefined") {
                            localStorage.setItem('spotterLoggedIn', data.token);
                            localStorage.setItem('spotterUrl', window.location.href.split('#')[0]);
                            localStorage.setItem('spotterClientSettings', JSON.stringify(data.user_client_settings));
                            // this.nickName = data.user_client_settings.nick_name
                            this.isLoggedIn = true;
                            this.$EventBus.$emit('loggedIn');
                            this.$root.$emit("bv::hide::modal", "modal-signup");
                            this.form.name = '';
                            this.form.email = '';
                            this.form.user_name = '';
                            this.form.password = '';
                            this.form.training_status = 1;
                            this.form.date_completed = '';
                            this.form.mobile_phone = '';
                            this.form.address = '';
                            this.form.dob = '';
                            this.$v.form.$reset();
                        } else {
                            this.$swal({
                                type: 'error',
                                title: 'Oops...',
                                text: data.message,
                            })
                        }
                    },
                    err => {
                        this.isDisabled = false;
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: err.data.message,
                        })
                    }
                )
            },
            logout() {
                var admin_user = JSON.parse(localStorage.getItem('user'));
                var citizen_user = JSON.parse(localStorage.getItem('spotterClientSettings'));
                console.log(citizen_user);
                console.log(admin_user);
                this.logoutFn();
                // if (citizen_user) {
                //     if (citizen_user['user_id'] === admin_user['id']) {
                //         this.logoutFn();
                //     } else {
                //         API.logoutCitizen(
                //             data => {
                //                 this.logoutFn();
                //             },
                //             err => {
                //             }
                //         )
                //     }
                // } else {
                //     API.logoutCitizen(
                //         data => {
                //             this.logoutFn();
                //         },
                //         err => {
                //         }
                //     )
                // }
            },
            forgetPassword() {
                this.$root.$emit("bv::hide::modal", "modal-login");
                this.$EventBus.$emit('openForgetPassword');
            },
            logoutFn() {
                localStorage.removeItem('spotterLoggedIn');
                localStorage.removeItem('spotterClientSettings');
                localStorage.removeItem('spotterUrl');
                localStorage.removeItem('app_setting');
                this.isLoggedIn = false;
            },
            login() {
                this.$v.email.$touch();
                this.$v.password.$touch();
                if (this.$v.email.$anyError || this.$v.password.$anyError) {
                    return;
                }
                let email = this.email
                let password = this.password
                let remember_be = this.remember_be
                let data = {
                    email: email,
                    password: password,
                    client_url: window.location.href.split('#')[0],
                }
                this.isDisabled = true;
                API.loginSpotter(
                    data,
                    data => {
                        this.isDisabled = false;
                        if (typeof data.token !== "undefined") {
                            localStorage.setItem('spotterLoggedIn', data.token);
                            localStorage.setItem('spotterUrl', window.location.href.split('#')[0]);
                            localStorage.setItem('spotterClientSettings', JSON.stringify(data.user_client_settings));
                            this.isLoggedIn = true;
                            this.$EventBus.$emit('loggedIn');
                            this.$EventBus.$emit('SpotterloggedIn');
                            this.$root.$emit("bv::hide::modal", "modal-login");
                        } else {
                            this.isDisabled = false;
                            this.$swal({
                                type: 'error',
                                title: 'Oops...',
                                text: data.message,
                            })
                        }
                    },
                    err => {
                        this.isDisabled = false;
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: err.message,
                        })
                    }
                )
            },
            addSpotModal() {

                if (this.isLoggedIn === true) {
                    this.$store.commit('increment')
                } else {
                    this.$swal({
                        type: 'error',
                        title: 'Oops...',
                        text: 'Please login to continue',
                    })
                }
            },
            recentSpots() {
                // bus
                // this.$EventBus.$emit('changeIt', 'changed header');
                this.list = [];
                this.page = 1;
                this.finished = false;
                this.fetch();
                this.$root.$emit("bv::show::modal", "recent-spots");
            },
            viewFieldGuide() {
                // this.$emit('openFieldGuide');
                this.$root.$emit("bv::show::modal", "field-guide");
            },
            home() {
                this.$EventBus.$emit('reinitializeMap');
            },
            onRefresh(done) {
                this.list = [];
                this.page = 1;
                this.finished = false;
                this.fetch();

                done();
            },
            onLoad(done) {
                if (this.page <= this.lastPage) {
                    this.fetch();
                } else {
                    // all data loaded
                    this.finished = true;
                }
                done();
            },
            fetch() {
                let data = {
                    pageSize: this.pageSize,
                    page: this.page,
                    url: window.location.href.split('#')[0],
                }
                API.getRecentSpots(
                    data,
                    data => {
                        this.defaultImage = data.default_image;
                        this.defaultImage2 = data.default_image2;
                        this.serverUrl = data.server_url;
                        for (let i = 0; i < data.data.data.length; i++) {
                            this.list.push(data.data.data[i]);
                        }
                        this.page++;
                    },
                    err => {
                        // if(!err.status) {
                        //     this.$root.$emit("bv::hide::modal", "recent-spots");
                        //     alert('Unathenticated User. You will be logged out.');
                        //     this.$root.$emit('logout-event');
                        // }
                    });
            },
            openSpotDetail(id,event) {
                this.$refs.spotDetails.openSpotterSpotDetail(id,true)
            },
            addAcoustic() {
                console.log('ASSAD',this.enable_acoustic);
                // if (this.enable_acoustic){
                    if (this.isLoggedIn === true) {
                        console.log('clicked')
                        this.$EventBus.$emit('addAcoustic');
                    } else {
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: 'Please login to continue',
                        })
                    }
                // }else{
                //     this.$swal({
                //         type: 'error',
                //         title: 'Oops...',
                //         text: 'Add Acoustic not Available',
                //     })
                // }

            },
            showAnalytics() {
                if (this.isLoggedIn === true) {
                    this.$EventBus.$emit('showAnalytics');
                } else {
                    this.$swal({
                        type: 'error',
                        title: 'Oops...',
                        text: 'Please login to continue',
                    })
                }
            },
            showMessaging() {
                if (this.isLoggedIn === true) {
                    this.$EventBus.$emit('showMessaging');
                } else {
                    this.$swal({
                        type: 'error',
                        title: 'Oops...',
                        text: 'Please login to continue',
                    })
                }
            },
            spotterSettings() {
                if (this.isLoggedIn === true) {
                    var user_item = JSON.parse(localStorage.getItem('spotterClientSettings'));
                    console.log(user_item.id);
                    const data = {
                        id: user_item.id,
                        client_url: window.location.href.split('#')[0]
                    }
                    API.getSpotterProfile(
                        data,
                        data => {
                            this.$root.$emit("bv::show::modal", "modal-settings");
                            this.$root.$emit("bv::hide::modal", "recent-spots");
                            this.$root.$emit("bv::hide::modal", "spot-detail");
                            console.log(data.data.user_name)
                            this.update_profile.id = data.data.id;
                            this.update_profile.name = data.data.name;
                            this.update_profile.email = data.data.users.email;
                            this.update_profile.user_name = data.data.user_name;
                            this.update_profile.mobile_phone = data.data.mobile_phone;
                            this.update_profile.dob = data.data.dob;
                        },
                        err => {
                        });
                } else {
                    this.$swal({
                        type: 'error',
                        title: 'Oops...',
                        text: 'Please login to continue',
                    })
                }
            },
            formatDate(date) {
                return moment(String(date)).format('MM-DD-YYYY HH:mm')
            },
            formatDateMM(date) {
                return moment(String(date)).format('MM-DD-YYYY')
            },
            getPageTitle() {
                const data = {
                    client_url: window.location.href.split('#')[0]
                }
                API.getPageTitle(
                    data,
                    data => {
                        if(data.status == 200) {
                            document.title = "WatchSpotter Project " + data.data;
                        }

                    },
                    err => {}
                );
            },
            checkImageType(item) {
                var store = false;
                if(item.primary_image === null) {
                    return false;
                } else {
                    var foundMp3 = item.primary_image.search(".mp3");
                    var foundAac = item.primary_image.search(".aac");
                    
                    if(foundMp3 !== -1 || foundAac !== -1 ){
                        store = true;
                    }
                }
                return store;
            }
        },
        destroyed() {
            window.removeEventListener("scroll", this.handleSCroll);
        },
        watch: {
            $route() {
                document
                    .querySelector(".bottom-navbar")
                    .classList.remove("header-toggled");
            }
        }
    };
</script>

<style scoped lang="scss">
    .carousal-audio {
        min-height: 200px;
    }
    .full-width {
        width: 100% !important;
    }
    .full-width .row div:nth-child(2) {
        text-align: center;
    }
    .full-width .row div {
        padding: 0px;
    }
    .full-width .row {
        margin: 0;
        flex-wrap: inherit;
    }
    .card{
        border: 2px solid whitesmoke;
        border-radius: 10px;
    }
    .md-img img {
        height: 100%;
        width: 100%;
    }
    .full-width .new-col-modal .row div {
        text-align: left !important;
    }
    .new-table-responsive  tr td span {
        white-space: pre-wrap;
        line-height: 1.2;
    }

    @media (max-width: 767px) {
    .m-row {
        flex-direction: column;
    }
}

</style>
