import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import './session'
import 'vue-select/dist/vue-select.css';
import InputColorPicker from "vue-native-color-picker";
import ToggleButton from 'vue-js-toggle-button'
import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
import VueTabs from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'
import store from './store'
import VueGeolocation from 'vue-browser-geolocation';
import Multiselect from 'vue-multiselect'
// Vue.prototype.$server_url = 'http://watch-spotter.test';
// Vue.prototype.$server_url = 'https://test.watchspotter.app';
Vue.prototype.$server_url = process.env.VUE_APP_ENDPOINT;
// Vue.prototype.$server_url = 'https://web.watchspotter.app';
Vue.prototype.$EventBus = new Vue();
import VueLoadmore from 'vuejs-loadmore';
import i18n from './i18n'

Vue.use(VueLoadmore, {
    lang: 'en-US'
});

Vue.use(ToggleButton)
Vue.use(InputColorPicker);
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
Vue.use(router)
Vue.use(store)
Vue.use(VueGoodTablePlugin);
Vue.use(VueFileAgent);
Vue.use(VueTabs);
Vue.use(VueGeolocation);
Vue.component(Multiselect);
Vue.config.productionTip = false
Vue.component('v-select', vSelect)
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')


