import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '@/i18n';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        click: 0,
        adminLanguage: i18n.locale,
        spotterLanguage: i18n.locale
    },
    getters: {
        myStoreValue: state => !!state.click
    },
    mutations: {
        increment(state) {
            state.click++;
        },
        decrement(state) {
            state.click = 0;
        },
        setAdminLanguage(state) {
            state.adminLanguage = i18n.locale
            console.log('state.adminLanguage :', state.adminLanguage);
        },
        setSpotterLanguage(state) {
            state.spotterLanguage = i18n.locale
            console.log('state.spotterLanguage :', state.spotterLanguage);
        }
    },
    actions: {},
    modules: {},
    plugins: [createPersistedState()],
})
