<template>
    <section class="app-footer">
        <footer class="footer">
            <div class="dis-flex">
                <span class="">
                   © {{new Date().getFullYear() }} WatchSpotter, LLC. {{ $t('all-rights') }}
                    <!--          <a href="http://www.bootstrapdash.com/" target="_blank">Bootstrapdash</a>. All rights reserved.-->
                </span>

                <p class="ml-auto mb-0"><span><a href="https://watchspotter.net/privacy-policy/" target="_blank">{{ $t('privacy-policy') }}</a></span>
                    <span class=""> |</span> <span><a href="https://www.watchspotter.net/terms-and-conditions" target="_blank">{{ $t('terms-and-conditions') }}</a> | </span> <span><a href="https://www.watchspotter.net/contact" target="_blank">{{ $t('contact-us') }}</a></span></p>
            </div>
        </footer>
    </section>
</template>

<script>
    export default {
        name: "app-footer"
    };
</script>
