<template>
    <div id="chartdiv3"></div>
</template>

<script>
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import API from '@/api'
    import { inject } from "vue";
    export default {
        props: ['date_from', 'date_to'],
        // inject: ['date_from', 'date_to', 'showLocationData'],
        data() {
            return {
                form: {
                    date_from: '',
                    date_to: '',
                    client_id: ''
                },
                datacollection: {
                    labels: [],
                    datasets: [{
                        data: [],
                    }]
                },
            }
        },
        beforeMount() {
            this.form.date_from = this.date_from;
            this.form.date_to = this.date_to;
            this.selected_client_id = JSON.parse(localStorage.getItem('client_id'));
            this.form.client_id = this.selected_client_id;
            this.getChartData();
        },
        mounted() {
        },
        created() {
            // this.$EventBus.$on("filterByDate", (data) => {
            //     this.getFormData(data);
            // });
            // this.$EventBus.$on("resetForm", (data) => {
            //     this.getFormData(data);
            // });
        },
        methods: {
            getFormData(data) {
                this.form.date_from = data.date_from;
                this.form.date_to = data.date_to;
                this.updateChartData()
            },
            getChartData() {
                // const date_form = inject("date_form", date_form);
                // console.log('date_form DLocation :', this.date_form);
                // const date_to = inject("date_to", date_to);
                this.form.date_from = this.$parent.form.date_from;   
                this.form.date_to = this.$parent.form.date_to;               
                API.getSpotPerLocation(this.form,
                    data => {
                        var sportArray = data.data
                        console.log(data.data);
         
                        var chart = am4core.create("chartdiv3", am4charts.PieChart);
                        if(chart.logo){
                            chart.logo.disabled = true;
                        }
                        chart.data = sportArray;
                        var pieSeries = chart.series.push(new am4charts.PieSeries());
                        pieSeries.dataFields.value = "location_count";
                        pieSeries.dataFields.category = "location_name";
                        // chart.events.on("ready", function(ev){
                        //     hideIndicator();
                        // });

                        // let indicator;
                        // let indicatorInterval;

                        // function showIndicator() {
                        // if (!indicator) {
                        //     indicator = chart.tooltipContainer.createChild(am4core.Container);
                        //     indicator.background.fill = am4core.color("#fff");
                        //     indicator.width = am4core.percent(100);
                        //     indicator.height = am4core.percent(100);

                        //     var indicatorLabel = indicator.createChild(am4core.Label);
                        //     indicatorLabel.text = "Loading ...";
                        //     indicatorLabel.align = "center";
                        //     indicatorLabel.valign = "middle";
                        //     indicatorLabel.dy = 50;
                            
                        //     var hourglass = indicator.createChild(am4core.Image);
                        //     hourglass.href = "https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-160/hourglass.svg";
                        //     hourglass.align = "center";
                        //     hourglass.valign = "middle";
                        //     hourglass.horizontalCenter = "middle";
                        //     hourglass.verticalCenter = "middle";
                        //     hourglass.scale = 0.4;
                        // }

                        // indicator.hide(0);
                        // indicator.show();

                        // clearInterval(indicatorInterval);
                        // indicatorInterval = setInterval(function() {
                        //     hourglass.animate([{
                        //     from: 0,
                        //     to: 360,
                        //     property: "rotation"
                        //     }], 2000);
                        // }, 3000);
                        // }

                        // function hideIndicator() {
                        // indicator.hide();
                        // clearInterval(indicatorInterval);
                        // }

                        // showIndicator();
                        pieSeries.ticks.template.disabled = true;
                        pieSeries.alignLabels = false;
                        pieSeries.labels.template.text = "{value}";
                        pieSeries.labels.template.radius = am4core.percent(-40);
                        pieSeries.labels.template.fill = am4core.color("white");
                        chart.legend = new am4charts.Legend();
                        chart.legend.position = "right";
                        chart.legend.scrollable = true;
                        chart.responsive.enabled = true;

                        window.addEventListener('resize', () => {
                            if (window.innerWidth <= 1199) {
                                chart.legend.position = "bottom";
                            } else {
                                chart.legend.position = "right";
                            }
                        }, true);
                        
                    },
                    err => {
                    }
                )
            },
            updateChartData() {
                API.getSpotPerLocation(this.form,
                    data => {
                        var sportArray = data.data         
                        var chart = am4core.create("chartdiv3", am4charts.PieChart);
                        if(chart.logo){
                            chart.logo.disabled = true;
                        }
                        chart.data = sportArray;
                        var pieSeries = chart.series.push(new am4charts.PieSeries());
                        pieSeries.dataFields.value = "location_count";
                        pieSeries.dataFields.category = "location_name";
                        // chart.events.on("ready", function(ev){
                        //     hideIndicator();
                        // });

                        // let indicator;
                        // let indicatorInterval;

                        // function showIndicator() {
                        // if (!indicator) {
                        //     indicator = chart.tooltipContainer.createChild(am4core.Container);
                        //     indicator.background.fill = am4core.color("#fff");
                        //     indicator.width = am4core.percent(100);
                        //     indicator.height = am4core.percent(100);

                        //     var indicatorLabel = indicator.createChild(am4core.Label);
                        //     indicatorLabel.text = "Loading ...";
                        //     indicatorLabel.align = "center";
                        //     indicatorLabel.valign = "middle";
                        //     indicatorLabel.dy = 50;
                            
                        //     var hourglass = indicator.createChild(am4core.Image);
                        //     hourglass.href = "https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-160/hourglass.svg";
                        //     hourglass.align = "center";
                        //     hourglass.valign = "middle";
                        //     hourglass.horizontalCenter = "middle";
                        //     hourglass.verticalCenter = "middle";
                        //     hourglass.scale = 0.4;
                        // }

                        // indicator.hide(0);
                        // indicator.show();

                        // clearInterval(indicatorInterval);
                        // indicatorInterval = setInterval(function() {
                        //     hourglass.animate([{
                        //     from: 0,
                        //     to: 360,
                        //     property: "rotation"
                        //     }], 2000);
                        // }, 3000);
                        // }

                        // function hideIndicator() {
                        // indicator.hide();
                        // clearInterval(indicatorInterval);
                        // }

                        // showIndicator();
                        pieSeries.ticks.template.disabled = true;
                        pieSeries.alignLabels = false;
                        // pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
                        pieSeries.labels.template.text = "{value}";
                        pieSeries.labels.template.radius = am4core.percent(-40);
                        pieSeries.labels.template.fill = am4core.color("white");
                        chart.legend = new am4charts.Legend();
                        chart.legend.position = "right";
                        chart.legend.scrollable = true;
                        chart.responsive.enabled = true;

                        window.addEventListener('resize', () => {
                            if (window.innerWidth <= 1199) {
                                chart.legend.position = "bottom";
                            } else {
                                chart.legend.position = "right";
                            }
                        }, true);
                    },
                    err => {
                    }
                )
            },
        }
    }
</script>

<style >
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

#chartdiv3{
  width: 100%;
  height: 330px;
}

@media (max-width: 480px) {
    #chartdiv3 {
        height: 350px;
    }
}

</style>