<template>
    <div id="chartdiv11"></div>
</template>

<script>
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    // import am4themes_animated from "@amcharts/amcharts4/themes/animated";
    import API from '@/api'

    export default {
        props: ['date_from', 'date_to'],
        data() {
            
            return {
                form: {
                    date_from: '',
                    date_to: '',
                    client_id: ''
                },
                datacollection: {
                    labels: [],
                    datasets: [{
                        data: [],
                    }]
                },
            }
        },
        beforeMount() {
            this.form.date_from = this.date_from;
            this.form.date_to = this.date_to;
            this.selected_client_id = JSON.parse(localStorage.getItem('client_id'));
            this.form.client_id = this.selected_client_id;
            this.getChartData();
        },
        mounted() {
        },
        created() {
            // this.$EventBus.$on("filterByDate", (data) => {
            // console.log('data data :', data);
            //     this.getFormData(data);
            // });
            // this.$EventBus.$on("resetForm", (data) => {
            //     this.getFormData(data);
            // });
        },
        methods: {
            getFormData(data) {
                this.form.date_from = data.date_from;
                this.form.date_to = data.date_to;
                this.updateChartData()
            },
            getChartData() {
                this.form.date_from = this.$parent.form.date_from;   
                this.form.date_to = this.$parent.form.date_to;    
                API.getSpotPerMonth(this.form,
                    data => {
                        var sportArray = data.data.spotArr
                        console.log(data.data);
         
                        var chart = am4core.create("chartdiv11", am4charts.PieChart);
                        if(chart.logo){
                            chart.logo.disabled = true;
                        }
                        chart.data = sportArray;
                        var pieSeries = chart.series.push(new am4charts.PieSeries());
                        pieSeries.dataFields.value = "count";
                        pieSeries.dataFields.category = "month";

                        pieSeries.ticks.template.disabled = true;
                        pieSeries.alignLabels = false;
                        pieSeries.labels.template.text = "{value}";
                        pieSeries.labels.template.radius = am4core.percent(-40);
                        pieSeries.labels.template.fill = am4core.color("white");
                        chart.legend = new am4charts.Legend();
                        chart.legend.position = "right";
                        chart.legend.scrollable = true;
                        chart.responsive.enabled = true;

                        window.addEventListener('resize', () => {
                            if (window.innerWidth <= 1199) {
                                chart.legend.position = "bottom";
                            } else {
                                chart.legend.position = "right";
                            }
                        }, true);
                        
                    },
                    err => {
                    }
                )
            },
            updateChartData() {
                API.getSpotPerMonth(this.form,
                    data => {
                        var sportArray = data.data.spotArr
                        console.log(data.data);
         
                        var chart = am4core.create("chartdiv11", am4charts.PieChart);
                        if(chart.logo){
                            chart.logo.disabled = true;
                        }
                        chart.data = sportArray;
                        var pieSeries = chart.series.push(new am4charts.PieSeries());
                        pieSeries.dataFields.value = "count";
                        pieSeries.dataFields.category = "month";

                        pieSeries.ticks.template.disabled = true;
                        pieSeries.alignLabels = false;
                        pieSeries.labels.template.text = "{value}";
                        pieSeries.labels.template.radius = am4core.percent(-40);
                        pieSeries.labels.template.fill = am4core.color("white");
                        chart.legend = new am4charts.Legend();
                        chart.legend.position = "right";
                        chart.legend.scrollable = true;
                        chart.responsive.enabled = true;

                        window.addEventListener('resize', () => {
                            if (window.innerWidth <= 1199) {
                                chart.legend.position = "bottom";
                            } else {
                                chart.legend.position = "right";
                            }
                        }, true);
                    },
                    err => {
                    }
                )
            },
        }
    }
</script>

<style >
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

#chartdiv11{
  width: 100%;
  height: 330px;
}

@media (max-width: 480px) {
    #chartdiv11{
        width: 100%;
        height: 350px;
    }
}

</style>